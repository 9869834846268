import React from "react";
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { LoginPageFormData } from "./LoginPage";
import { FormikProps } from "formik/dist/types";

const useStyles = makeStyles((theme) => ({
  Login__Form: {
    margin: "0px 30px ",
    // color: "#fff",
  },
  Login__Inputfield: {
    display: "block",
  },
  Login__Btn: {
    // backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      // backgroundColor: "#eee",
    },

    textDecoration: "none",
  },
  Login__BtnLoader: {
    position: "absolute",
    bottom: "0",
    height: "4px",
    left: "0",
    right: "0",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    backgroundColor: "transparent",

    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  Login__BoxBtns: {
    display: "flex",
    flexDirection: "column",
    margin: "80px 100px",
  },

  Login__PasswordBtn: {
    color: theme.palette.primary.light,
  },
}));

const LoginPageForm = (props: FormikProps<LoginPageFormData>) => {
  const classes = useStyles();

  const {
    values: { email, password },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = props;

  return (
    <form
      noValidate
      autoComplete="off"
      className={classes.Login__Form}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="email"
            helperText={touched.email && errors.email ? errors.email : ""}
            error={!!errors.email}
            label="email address"
            fullWidth
            variant="outlined"
            value={email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="password"
            label="password"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={handleChange}
            helperText={
              touched.password && errors.password ? errors.password : ""
            }
            error={!!errors.password}
          />
        </Grid>
      </Grid>

      <Box
        className={classes.Login__BoxBtns}
        display="flex"
        justifyContent="space-between"
      >
        <Button
          type="submit"
          className={classes.Login__Btn}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <LinearProgress className={classes.Login__BtnLoader} />
          )}
          Log in
        </Button>

        <Button
          href="/unauthorized/forgot-password"
          className={classes.Login__PasswordBtn}
        >
          Forgot password?
        </Button>
      </Box>
    </form>
  );
};

export default LoginPageForm;

import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import LoginPageForm from "./LoginPageForm";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import sleep from "../../Ext/sleep";
import get_api from "../../Ext/get_api";
import { AxiosError } from "axios";
import globalAxios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  Login__Card: {
    marginTop: "80px",
    height: "500px",
    // backgroundColor: theme.palette.primary.dark,
  },
  Login__Typography: {
    // color: "#fff",
    marginLeft: "20px",
  },
  Login__SubTypography: {
    // color: "#C8C8C8",
    marginLeft: "20px",
  },
}));

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must contain atleast 8 characters")
    .required("Enter your password"),
});

export type LoginPageFormData = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const classes = useStyles();
  let history = useHistory();

  const values: LoginPageFormData = { email: "", password: "" };
  const Submit = async (
    data: LoginPageFormData,
    actions: FormikHelpers<LoginPageFormData>
  ) => {
    const api = get_api();
    // je zult deze zelf even moet aanpassen en de correcte data doorgeven
    // onderstaande kun je gebruiken om mee in te loggen, want dat account bestaat
    // wanneer je inlogt met de verkeerde gegevens geeft hij een foutmelding
    // deze moet je afvangen en de juiste foutmeldingen in het formulier tonen.

    try {
      await sleep(350);

      const res = await api.crmPersonAuthenticatePost({
        email: data.email,
        password: data.password,
      });

      localStorage.setItem("TOKEN", res.data.token);
      globalAxios.defaults.headers["TOKEN"] = res.data.token;

      // After talking to the API, mark the form done.
      actions.setSubmitting(false);
      history.push("/authorized/mailer");
    } catch (e) {
      if (e.response) {
        const exc: AxiosError = e;
        const res = exc.response;
        //console.log(res?.status, res?.data, res?.headers);

        if (res?.data.detail === "NOT_FOUND") {
          actions.setErrors({
            email: "Account not found",
          });
        } else if (res?.data.detail === "ACCOUNT_PASSWORD_INVALID") {
          actions.setErrors({
            password: "password incorrect try again",
          });
        } else {
          actions.setErrors({ email: `Unknown error: ${res?.data.detail}` });
        }
      } else {
        console.error(e);
        actions.setErrors({ email: `System error` });
      }
    }
  };
  return (
    <Container maxWidth="sm">
      <Card className={classes.Login__Card}>
        <CardContent>
          <Typography
            className={classes.Login__Typography}
            variant="h5"
            component="h2"
          >
            Authentication
          </Typography>
          <Typography
            className={classes.Login__SubTypography}
            gutterBottom
            style={{ marginLeft: "20px" }}
          >
            Please login with your username and password
          </Typography>
        </CardContent>
        <CardContent>
          <Formik<LoginPageFormData>
            initialValues={values}
            validationSchema={validationSchema}
            render={(props) => <LoginPageForm {...props} />}
            onSubmit={Submit}
          />
        </CardContent>
        <CardActions></CardActions>
      </Card>
    </Container>
  );
};

export default LoginPage;

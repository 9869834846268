import React from "react";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  LinearProgress,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik, FormikHelpers, FormikProps } from "formik";
import {
  CdnMailerManyRequest,
  CdnMailerRequestMessageTemplateEnum,
} from "../../api";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import isValid from "date-fns/isValid";
import format from "date-fns/format";

const useStyles = makeStyles((theme) => ({
  Drawer_Container: {
    "& .MuiPaper-root": {
      width: "40vw",
    },
  },

  Drawer_SubmitBtnProgress: {
    position: "absolute",
    bottom: "0px",
    height: "4px",
    left: "0px",
    right: "0px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    backgroundColor: "transparent",

    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

type FormData = Omit<CdnMailerManyRequest, "order_by" | "page" | "amount">;

type Props = {
  initial: FormData;
  open: boolean;
  onClose: (data?: FormData) => void;
};

const HistoryPageFilterDrawer: React.FC<Props> = (props) => {
  const classes = useStyles();

  const onSubmit = async (data: FormData, actions: FormikHelpers<FormData>) => {
    actions.setSubmitting(true);
    props.onClose(data);
  };

  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={() => props.onClose()}
      className={classes.Drawer_Container}
    >
      <Formik<FormData> initialValues={props.initial} onSubmit={onSubmit}>
        {(fp: FormikProps<FormData>) => {
          const { values, handleSubmit, handleChange, isSubmitting } = fp;

          const handleDateChange = (name: string) => {
            return (date: any) => {
              // the name refers to the value and error
              // of this field. In fact, this would be the [name=""].
              if (isValid(date)) {
                // When the data picker returns a valid date
                // , we store the value in Formik.
                //console.log(FormikProps, "setfieldvalue?");
                fp.setFieldValue(name, format(date, "yyyy-MM-dd"));
              } else {
                //  datepicker return an invalid date,
                // then we store null so the API doesn't complain.
                //console.log(name, "name?");
                fp.setFieldValue(name, null);
              }
            };
          };

          return (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              style={{ height: "100%" }}
            >
              <Box
                p={2}
                paddingTop={4}
                display="flex"
                flexDirection="column"
                height="100%"
              >
                <DialogTitle disableTypography>
                  <Typography variant="h4">Filter</Typography>
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        name="search"
                        label="Search"
                        fullWidth
                        helperText="From field search"
                        onChange={handleChange}
                        value={values.search || ""}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        name="template"
                        select
                        label="Template"
                        fullWidth
                        helperText="Please select template"
                        onChange={handleChange}
                        value={values.template || ""}
                      >
                        <MenuItem
                          value={
                            CdnMailerRequestMessageTemplateEnum.Progression
                          }
                        >
                          Progression
                        </MenuItem>
                        <MenuItem
                          value={CdnMailerRequestMessageTemplateEnum.Rfo}
                        >
                          RFO
                        </MenuItem>
                        <MenuItem
                          value={CdnMailerRequestMessageTemplateEnum.RfoChange}
                        >
                          RFO Change
                        </MenuItem>
                        <MenuItem
                          value={CdnMailerRequestMessageTemplateEnum.Rfs}
                        >
                          RFS
                        </MenuItem>
                        <MenuItem
                          value={CdnMailerRequestMessageTemplateEnum.Issue}
                        >
                          Issue
                        </MenuItem>
                        <MenuItem
                          value={
                            CdnMailerRequestMessageTemplateEnum.OrderConfirmation
                          }
                        >
                          Order Confirmation
                        </MenuItem>
                        <MenuItem
                          value={CdnMailerRequestMessageTemplateEnum.Simple}
                        >
                          Simple
                        </MenuItem>
                        <MenuItem
                          value={
                            CdnMailerRequestMessageTemplateEnum.ResearchInvite
                          }
                        >
                          Research Invite
                        </MenuItem>
                        <MenuItem
                          value={CdnMailerRequestMessageTemplateEnum.SisuOrRfo}
                        >
                          SISU or RFO
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={12}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          label="Created after"
                          value={values.created_after || null}
                          onChange={handleDateChange("created_after")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          label="Created before"
                          value={values.created_before || null}
                          onChange={handleDateChange("created_before")}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => props.onClose()} variant="outlined">
                    cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="outlined"
                    color="primary"
                  >
                    {isSubmitting && (
                      <LinearProgress
                        className={classes.Drawer_SubmitBtnProgress}
                      />
                    )}
                    save
                  </Button>
                </DialogActions>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Drawer>
  );
};

export default HistoryPageFilterDrawer;

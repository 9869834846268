import React from "react";
import LoginRecoverPasswordPageForm from "./LoginRecoverPasswordPageForm";
import {
  Card,
  CardContent,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import sleep from "../../Ext/sleep";
import get_api from "../../Ext/get_api";
import { AxiosError } from "axios";
import globalAxios from "axios";

const useStyles = makeStyles((theme) => ({
  Login__Card: {
    marginTop: "80px",
    height: "500px",
    backgroundColor: theme.palette.primary.dark,
  },
  Login__Typography: {
    color: "#fff",
    marginLeft: "20px",
  },
  Login__SubTypography: {
    color: "#C8C8C8",
    marginLeft: "20px",
  },
}));
export type LoginRecoverPasswordPageFormData = {
  email: string;
  password: string;
  code_token: string;
};
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must contain atleast 8 characters")
    .required("Enter your password"),
  code_token: Yup.string().min(1),
});

const LoginRecoverPasswordPage = (props: any) => {
  const classes = useStyles();
  let history = useHistory();

  const values: LoginRecoverPasswordPageFormData = {
    email: "",
    password: "",
    code_token: "",
  };
  const code_id = window.history.state.state.code_id;
  const Submit = async (
    data: LoginRecoverPasswordPageFormData,
    actions: FormikHelpers<LoginRecoverPasswordPageFormData>
  ) => {
    const api = get_api();
    try {
      await sleep(350);
      if (code_id !== null) {
        const cps_res = await api.crmPersonResetPasswordPost({
          email: data.email,
          password: data.password,
          code_id: code_id,
          code_token: data.code_token,
        });

        localStorage.setItem("TOKEN", cps_res.data.token);
        globalAxios.defaults.headers["TOKEN"] = cps_res.data.token;

        // After talking to the API, mark the form done.
        actions.setSubmitting(false);
        history.push("/authorized/dashboard");
      }
    } catch (e) {
      const exc: AxiosError = e;
      const res = exc.response;
      console.log(res?.status, res?.data, res?.headers);
      if (res?.data.detail[0].type === "value_error.email") {
        actions.setErrors({
          email: "Please enter a valid email",
        });
      } else if (res?.data.detail === "TOKEN_INVALID") {
        actions.setErrors({
          code_token: "please enter code",
        });
      } else if (res?.data.detail === "ACCOUNT_PASSWORD_INVALID") {
        actions.setErrors({
          password: "password incorrect try again",
        });
      } else {
        actions.setErrors({ email: `Unknown error: ${res?.data.detail}` });
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Card className={classes.Login__Card}>
        <CardContent>
          <Typography
            className={classes.Login__Typography}
            variant="h5"
            component="h2"
          >
            Reset password
          </Typography>
          <Typography
            className={classes.Login__SubTypography}
            gutterBottom
            style={{ marginLeft: "20px" }}
          >
            Please enter the verfication code you received and your new password
          </Typography>
        </CardContent>
        <CardContent>
          <Formik<LoginRecoverPasswordPageFormData>
            initialValues={values}
            validationSchema={validationSchema}
            render={(props) => <LoginRecoverPasswordPageForm {...props} />}
            onSubmit={Submit}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default LoginRecoverPasswordPage;

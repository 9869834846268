import React from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { FormikProps } from "formik";
import { MailerFormData } from "./MailerPage";

const MailerPageMessagesModuleForm = (props: FormikProps<MailerFormData>) => {
  const { values, errors, touched, handleChange } = props;
  const {
    language,
    template,
    addressee_name,
    addressee_email,
    subject,
    introduction,
  } = values.message;
  return (
    <Grid container style={{ margin: "0px 20px 0px 20px" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            select
            label="Language"
            fullWidth
            name="message.language"
            error={!!errors.message?.language}
            value={language || ""}
            onChange={handleChange}
            defaultValue="nl"
            helperText={
              touched.message?.language
                ? !!errors.message?.language
                : errors.message?.language
            }
          >
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="fr">FR</MenuItem>
            <MenuItem value="nl">NL</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            select
            label="Template"
            fullWidth
            name="message.template"
            error={!!errors.message?.template}
            value={template || ""}
            onChange={handleChange}
            defaultValue="Image-1"
          >
            <MenuItem value="SIMPLE">SIMPLE</MenuItem>
            <MenuItem value="ISSUE">ISSUE</MenuItem>
            <MenuItem value="PROGRESSION">PROGRESSION</MenuItem>
            <MenuItem value={"ORDER_CONFIRMATION"}>ORDER_CONFIRMATION</MenuItem>
            <MenuItem value={"ORDER_CONFIRMATION_SALES"}>ORDER_CONFIRMATION_SALES</MenuItem>
            <MenuItem value={"RESEARCH_INVITE"}>RESEARCH_INVITE</MenuItem>
            <MenuItem value={"SISU_OR_RFO"}>SISU_OR_RFO</MenuItem>
            <MenuItem value={"RFO_CHANGE"}>RFO_CHANGE</MenuItem>
            <MenuItem value={"RFS"}>RFS</MenuItem>
            <MenuItem value={"RFS_MUTATION"}>RFS_MUTATION</MenuItem>
            <MenuItem value={"RFO"}>RFO</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <TextField
            select
            label="Subject"
            fullWidth
            name="message.subject"
            error={!!errors.message?.subject}
            value={subject || ""}
            onChange={handleChange}
            defaultValue="Image-1"
          >
            <MenuItem value="Subject-1">subject-1</MenuItem>
            <MenuItem value="Subject-2">subject-2</MenuItem>
            <MenuItem value="Subject-3">subject-3</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            multiline
            maxRows={4}
            name="message.addressee_email"
            value={addressee_email || ""}
            error={!!errors.message?.addressee_email}
            helperText={
              touched.message?.addressee_email
                ? !!errors.message?.addressee_email
                : ""
            }
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            multiline
            maxRows={4}
            name="message.addressee_name"
            value={addressee_name || ""}
            error={!!errors.message?.addressee_name}
            helperText={
              touched.message?.addressee_name
                ? !!errors.message?.addressee_name
                : ""
            }
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Introduction"
            multiline
            maxRows={4}
            name="message.introduction"
            value={introduction || ""}
            error={!!errors.message?.introduction}
            helperText={
              touched.message?.introduction
                ? !!errors.message?.introduction
                : ""
            }
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MailerPageMessagesModuleForm;

import React, { useMemo } from "react";

import { makeStyles, TableCell, TableRow } from "@material-ui/core";

import ManagementLanguagePageTableCell from "./ManagementLanguagePageTableCell";
import { AppTranslationDto } from "../../api";

const useStyles = makeStyles((theme) => ({
  Table_TableCell: {
    borderRight: "1px solid #C0C0C0",
  },
}));

type ManagementPageTableRowProps = {
  id: number;
  title: string;
  translation_set: AppTranslationDto[];
  load: () => void;
};

const ManagementLanguagePageDataRows: React.FC<ManagementPageTableRowProps> = (
  props
) => {
  const classes = useStyles();
  const lastUpdatedAt = useMemo(() => {
    const value = props.translation_set
      .filter((x) => !!x.updated_at)
      .map((x) => new Date(x.updated_at!))
      .reduce((a, b) => (a! > b! ? a : b), new Date("1900-01-01T00:00:00"));
    return value.getFullYear() === 1900
      ? null
      : new Intl.DateTimeFormat("en", {
          dateStyle: "medium",
          timeStyle: "short",
        } as any).format(value);
  }, [props.translation_set]);

  return (
    <>
      <TableRow key={props.id}>
        <TableCell
          width={1}
          component="th"
          scope="row"
          className={classes.Table_TableCell}
        >
          #{props.id} {props.title}
        </TableCell>
        <TableCell width={210} className={classes.Table_TableCell}>
          {lastUpdatedAt}
        </TableCell>

        {props.translation_set.map((translation, index) => (
          <ManagementLanguagePageTableCell
            language={translation.language_id.toString()}
            value={translation.value}
            id={translation.localization_id}
            key={index}
            load={props.load}
          />
        ))}
      </TableRow>
    </>
  );
};

export default ManagementLanguagePageDataRows;

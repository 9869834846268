import React from "react";
import CoreLayout from "./Ext/Layouts/CoreLayout";
import LoginForgotPasswordPage from "./Pages/LoginPage/LoginForgotPasswordPage";
import LoginRecoverPasswordPage from "./Pages/LoginPage/LoginRecoverPasswordPage";
import MailerPage from "./Pages/MailerPage/MailerPage";
import Home from "./Pages/Homepage/HomeViewPage";
import ManagementUserPage from "./Pages/ManagementUserPage/ManagementUserPage";
import ManagementLanguagePage from "./Pages/ManagmentLanguagePage/ManagementLanguagePage";
import HistoryPage from "./Pages/HistoryPage/HistoryPage";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import UnauthorizedLayout from "./Ext/Layouts/UnauthorizedLayout";
import LoginPage from "./Pages/LoginPage/LoginPage";
import AuthorizedLayout from "./Ext/Layouts/AuthorizedLayout";

function App() {
  return (
    <Router>
      <Route path="/" exact>
        <Switch>
          <Redirect to="/unauthorized/login" exact />
        </Switch>
      </Route>

      <CoreLayout>
        <Route path="/unauthorized">
          <UnauthorizedLayout>
            <Switch>
              <Route path="/unauthorized" exact component={Home} />s
              <Route path="/unauthorized/login" component={LoginPage} exact />
              <Route
                path="/unauthorized/forgot-password"
                component={LoginForgotPasswordPage}
                exact
              />
              <Route
                path="/unauthorized/recover-password"
                exact
                component={LoginRecoverPasswordPage}
              />
            </Switch>
          </UnauthorizedLayout>
        </Route>

        <Route path="/authorized">
          <AuthorizedLayout>
            <Switch>
              <Route path="/authorized/mailer" component={MailerPage} exact />
              <Route
                path="/authorized/mailer/:id"
                component={MailerPage}
                exact
              />
              <Route
                path="/authorized/language"
                component={ManagementLanguagePage}
              />
              <Route
                path="/authorized/users"
                exact
                component={ManagementUserPage}
              />
              <Route path="/authorized/history" exact component={HistoryPage} />
            </Switch>
          </AuthorizedLayout>
        </Route>
      </CoreLayout>
    </Router>
  );
}

export default App;

import { Configuration, DefaultApi } from "../api";
import globalAxios from "axios";

export default function get_api() {
  let url = `http://localhost:9467`;
  if (window.location.hostname === "excite.eurofiber.com") {
    url = `https://excite.eurofiber.com`;
  } else if (window.location.hostname === "excite-test.eurofiber.com") {
    url = `https://excite-test.eurofiber.com`;
  }

  globalAxios.defaults.headers["TOKEN"] = localStorage.getItem("TOKEN");

  return new DefaultApi(new Configuration(), url);
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import { MailerFormData } from "./MailerPage";
import { FormikProps } from "formik";
import MailerPageProjectConnectionForm from "./MailerPageProjectConnectionForm";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import format from "date-fns/format";
import isValid from "date-fns/isValid";

const MailerPageProjectModuleForm = (props: FormikProps<MailerFormData>) => {
  const { values, errors, touched, handleChange, setFieldValue } = props;

  const {
    status,
    number,
    title,
    product,
    date_delivery_expected,
    date_ordered,
    contractor,
    contact,
    customer_code,
    customer_name,
    manager_code,
    manager_name,
    manager_email,
    manager_phone,
  } = values.project;

  // Wil je in je formulier een amount bij houden, om zo
  // "meedere formulieren" van een bepaalde type te maken,
  // dan kun je gebruik maken van een losse useState.
  const [connectionAmount, setConnectionAmount] = useState(0);
  const [connectionIndex, setConnectionIndex] = useState(0);

  // globale handleDateChange.

  const handleDateChange = (name: string) => {
    return (date: any) => {
      // the name refers to the value and error
      // of this field. In fact, this would be the [name=""].
      if (isValid(date)) {
        // When the data picker returns a valid date
        // , we store the value in Formik.
        setFieldValue(name, format(date, "yyyy-MM-dd"));
      } else {
        //  datepicker return an invalid date,
        // then we store null so the API doesn't complain.
        setFieldValue(name, null);
      }
    };
  };

  useEffect(() => {
    if (values.project.connection_set?.length) {
      setConnectionAmount(values.project.connection_set.length);
    } else {
      setConnectionAmount(0);
    }
  }, [values.project.connection_set?.length]);

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Number"
            name="project.number"
            value={number}
            helperText={touched.project?.number ? !!errors.project?.number : ""}
            onChange={handleChange}
            error={!!errors.project?.number}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            label="Status"
            fullWidth
            helperText="Please select status"
            name="project.status"
            value={status}
            onChange={handleChange}
            error={!!errors.project?.status}
            defaultValue="status-1"
          >
            <MenuItem value="ON_HOLD">ON_HOLD</MenuItem>
            <MenuItem value="PREPARATION">PREPARATION</MenuItem>
            <MenuItem value="ENGINEERING">ENGINEERING</MenuItem>
            <MenuItem value="EXECUTION">EXECUTION</MenuItem>
            <MenuItem value="DELIVERY">DELIVERY</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Title"
            name="project.title"
            value={title}
            helperText={touched.project?.title ? !!errors.project?.title : ""}
            onChange={handleChange}
            error={!!errors.project?.title}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Product"
            name="project.product"
            value={product}
            helperText={
              touched.project?.product ? !!errors.project?.product : ""
            }
            onChange={handleChange}
            error={!!errors.project?.product}
          />
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={6}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd-MM-yyyy"
              margin="normal"
              label="Delivery Expected"
              value={date_delivery_expected || null}
              helperText={errors.project?.date_delivery_expected}
              error={!!errors.project?.date_delivery_expected}
              onChange={handleDateChange("project.date_delivery_expected")}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd-MM-yyyy"
              margin="normal"
              label="Ordered"
              value={date_ordered || null}
              onChange={handleDateChange("project.date_ordered")}
              helperText={errors.project?.date_ordered}
              error={!!errors.project?.date_ordered}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Contractor"
            name="project.contractor"
            value={contractor}
            helperText={
              touched.project?.contractor ? !!errors.project?.contractor : ""
            }
            onChange={handleChange}
            error={!!errors.project?.contractor}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Contact"
            name="project.contact"
            value={contact}
            helperText={
              touched.project?.contact ? !!errors.project?.contact : ""
            }
            onChange={handleChange}
            error={!!errors.project?.contact}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Customer Name"
            name="project.customer_name"
            value={customer_name}
            helperText={
              touched.project?.customer_name
                ? !!errors.project?.customer_name
                : ""
            }
            onChange={handleChange}
            error={!!errors.project?.customer_name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Customer Code"
            name="project.customer_code"
            value={customer_code}
            helperText={
              touched.project?.customer_code
                ? !!errors.project?.customer_code
                : ""
            }
            onChange={handleChange}
            error={!!errors.project?.customer_code}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Manager Code"
            name="project.manager_code"
            value={manager_code}
            helperText={
              touched.project?.manager_code
                ? !!errors.project?.manager_code
                : ""
            }
            onChange={handleChange}
            error={!!errors.project?.manager_code}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Manager Name"
            name="project.manager_name"
            value={manager_name}
            helperText={
              touched.project?.manager_name
                ? !!errors.project?.manager_name
                : ""
            }
            onChange={handleChange}
            error={!!errors.project?.manager_name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Manager Email"
            name="project.manager_email"
            value={manager_email}
            helperText={
              touched.project?.manager_email
                ? !!errors.project?.manager_email
                : ""
            }
            onChange={handleChange}
            error={!!errors.project?.manager_email}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Manager Phone"
            name="project.manager_phone"
            value={manager_phone}
            helperText={
              touched.project?.manager_phone
                ? !!errors.project?.manager_phone
                : ""
            }
            onChange={handleChange}
            error={!!errors.project?.manager_phone}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Connection Amount"
            name="connectionAmount"
            value={connectionAmount.toString()}
            onChange={(evt) =>
              setConnectionAmount(parseInt(evt.target.value, 10) || 0)
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={connectionIndex}
              variant="scrollable"
              scrollButtons="on"
            >
              {[...Array(connectionAmount)].map((x, ix) => (
                <Tab
                  label={`#Connection ${ix + 1}`}
                  key={ix}
                  value={ix}
                  onClick={() => setConnectionIndex(ix)}
                />
              ))}
            </Tabs>
          </Paper>
        </Grid>

        <MailerPageProjectConnectionForm
          connection={values.project?.connection_set?.[connectionIndex]}
          connectionIndex={connectionIndex}
          {...props}
        />
      </Grid>
    </Box>
  );
};

export default MailerPageProjectModuleForm;

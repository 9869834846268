import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import sleep from "../../Ext/sleep";
import get_api from "../../Ext/get_api";
import { AxiosError } from "axios";

const useStyles = makeStyles((theme) => ({
  Dialog_SubmitBtnProgress: {
    position: "absolute",
    bottom: "0px",
    height: "4px",
    left: "0px",
    right: "0px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    backgroundColor: "transparent",

    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  email: string;
  id: number;
  load: () => void;
};

type FormData = {
  newPassword: string;
  code_token: string;
  email: string;
};

const validationSchema = Yup.object({
  newPassword: Yup.string().min(8),
  code_token: Yup.string().min(1),
  email: Yup.string().min(1).email("Enter a valid email"),
});
const ManagementUserPageRecoverPasswordDialog: React.FC<Props> = (props) => {
  const classes = useStyles();
  const values = { newPassword: "", code_token: "", email: props.email };
  const [code_id, setCodeId] = useState<number | null>(null);

  const onSubmit = async (data: FormData, actions: FormikHelpers<FormData>) => {
    const api = get_api();
    try {
      await sleep(250);

      if (code_id === null) {
        // step 1
        const cpa_res = await api.crmPersonResetPasswordCodePost({
          email: props.email,
        });

        setCodeId(cpa_res.data.code_id);
        console.log("Use the following code:", cpa_res.data.code_token);
      } else {
        // step 2
        await api.crmPersonResetPasswordPost({
          email: props.email,
          password: data.newPassword,
          code_id: code_id,
          code_token: data.code_token,
        });
      }

      actions.setSubmitting(false);
      props.load();
    } catch (e) {
      if (e.response) {
        const exc: AxiosError = e;
        const res = exc.response;
        console.log(res?.status, res?.data, res?.headers);
        if (res?.data.detail[0].type === "value_error.email") {
          actions.setErrors({
            email: "Please enter a valid email",
          });
        } else if (res?.data.detail === "TOKEN_INVALID") {
          actions.setErrors({
            newPassword: "please enter code",
          });
        }
      } else {
        actions.setErrors({ newPassword: "something went wrong" });
      }
    }
  };

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <Formik<FormData>
        validationSchema={validationSchema}
        initialValues={values}
        onSubmit={onSubmit}
      >
        {(fp: FormikProps<FormData>) => {
          const {
            values: { newPassword, code_token, email },
            touched,
            errors,
            handleSubmit,
            handleChange,
            isSubmitting,
          } = fp;

          return (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              style={{ height: "100%" }}
            >
              <Box padding={4}>
                <DialogTitle disableTypography>
                  <Typography variant="h4">Recover password</Typography>
                  <Typography variant="subtitle2">
                    send recover code to this email address
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        name="email"
                        value={email}
                        fullWidth
                        label="email address"
                        disabled={code_id !== null}
                        helperText={
                          touched.email && !!errors.email ? errors.email : ""
                        }
                        onChange={handleChange}
                      />
                    </Grid>

                    {code_id === null && (
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <LinearProgress
                            className={classes.Dialog_SubmitBtnProgress}
                          />
                        )}
                        send code
                      </Button>
                    )}

                    {code_id !== null && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            name="code_token"
                            value={code_token}
                            error={!!errors.code_token}
                            onChange={handleChange}
                            fullWidth
                            label="recover code"
                            helperText={
                              touched.code_token && !!errors.code_token
                                ? errors.code_token
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="newPassword"
                            value={newPassword}
                            error={!!errors.newPassword}
                            onChange={handleChange}
                            fullWidth
                            label="new password"
                            helperText={
                              touched.newPassword && !!errors.newPassword
                                ? errors.code_token
                                : ""
                            }
                          />
                        </Grid>
                        <DialogActions>
                          <Button variant="outlined" onClick={props.onClose}>
                            close
                          </Button>
                          <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting && (
                              <LinearProgress
                                className={classes.Dialog_SubmitBtnProgress}
                              />
                            )}
                            save password
                          </Button>
                        </DialogActions>
                      </>
                    )}
                  </Grid>
                </DialogContent>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ManagementUserPageRecoverPasswordDialog;

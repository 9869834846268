import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import MailerPageGeneralForm from "./MailerPageGeneralForm";
import MailerPageMessagesModuleForm from "./MailerPageMessagesModuleForm";

import MailerPageProjectModuleForm from "./MailerPageProjectModuleForm";

import * as Yup from "yup";
import get_api from "../../Ext/get_api";
import {
  CdnMailerAttachmentDto,
  CdnMailerRequestAddresseeDto,
  CdnMailerRequestConnectionStatusEnum,
  CdnMailerRequestDto,
  CdnMailerRequestMessageDto,
  CdnMailerRequestMessageTemplateEnum,
  CdnMailerRequestProgressDto,
  CdnMailerRequestProjectDto,
  CdnMailerRequestProjectStatusEnum,
  CdnMailerSendResponse,
} from "../../api";
import { AxiosError } from "axios";
import { Formik, FormikProps } from "formik";
import dot from "dot-object";
import MailerPageProgressModuleForm from "./MailerPageProgressModuleForm";
import { useParams } from "react-router-dom";
import { Email } from "@material-ui/icons";
import sleep from "../../Ext/sleep";

const useStyles = makeStyles((theme) => ({
  Module__Content: {
    height: "calc(100vh - 150px)",
    overflow: "auto",
  },
  image: {
    borderLeft: "1px solid gray",
    height: "calc(100vh - 60px)",
    overflow: "auto",
  },

  Logo: {
    width: "80px",
    height: "50px",
    backgroundPosition: "left",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  Module__Title: {
    marginLeft: "20px",
  },
  Module__SendBtn: {
    backgroundColor: theme.palette.primary.dark,
    padding: "16px",
    color: "#fff",
    margin: "0 10px",
    whiteSpace: "nowrap",
  },
  Module__AppBar: {
    backgroundColor: theme.palette.primary.light,
  },

  Module__Accordion: {
    "&:not(:first-child)": {
      marginTop: "10px",
    },
  },
}));

export type MailerFormData = {
  foreign_id: string;
  from_address: FromAddressData;
  reply_address: ReplyAddressData;
  expectation_video: boolean;
  debug_mode: boolean;
  deliver: boolean;
  to_address_set: CdnMailerRequestAddresseeDto[];
  cc_address_set: CdnMailerRequestAddresseeDto[];
  bcc_address_set: CdnMailerRequestAddresseeDto[];
  attachment_set: CdnMailerAttachmentDto[];
  message: CdnMailerRequestMessageDto;
  project: CdnMailerRequestProjectDto;
  progress: CdnMailerRequestProgressDto;
};

type FromAddressData = {
  name: string;
  email: string;
};
type ReplyAddressData = {
  name: string;
  email: string;
};

const validationSchema = Yup.object({
  name: Yup.string().min(1),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  addressee_name: Yup.string().min(1),
  addressee_email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  language: Yup.string().required("langauage is required"),
});

const initialDefault: MailerFormData = {
  to_address_set: [],
  cc_address_set: [],
  bcc_address_set: [],
  attachment_set: [],
  foreign_id: "",
  from_address: {
    name: "Eurofiber",
    email: "info@eurofiber.com",
  },
  reply_address: {
    name: "Eurofiber",
    email: "info@eurofiber.com",
  },
  expectation_video: false,
  debug_mode: false,
  deliver: true,

  message: {
    language: "nl",
    template: "ISSUE" as CdnMailerRequestMessageTemplateEnum,
    addressee_name: "",
    addressee_email: "",
    subject: "",
    introduction: "",
  },
  project: {
    status: "PREPARATION" as CdnMailerRequestProjectStatusEnum,
    number: "",
    title: "",
    product: "",
    date_delivery_expected: undefined,
    date_ordered: undefined,
    contractor: "",
    contact: "",
    customer_code: "",
    customer_name: "",
    manager_code: "",
    manager_name: "",
    manager_email: "",
    manager_phone: "",

    connection_set: [
      {
        status: "PREPARATION" as CdnMailerRequestConnectionStatusEnum,

        milestone_set: [],
        issue_set: [],
      },
    ],
  },
  progress: {
    has_nni: false,
    has_pop: false,
    has_loa: false,
    has_turnaround_permit: false,
    has_executive_activities: false,
    nni: {
      network_element: "",
      vlan_range: "",
      cabinet_id: "",
      network_type_element: "",
      gate_position: "",
      transceiver: "",
    },
    pop: {
      cabinet_id: "",
      network_element: "",
      gate_position: "",
      transceiver: "",
    },
    turnaround_permit: {
      weeks: 1,
      has_soil_survey: false,
    },
    executive_activities: {
      weeks: 1,
      has_controlled_drilling: false,
      has_dependency_project: false,
      project_number: "",
      has_top_10_weld: false,
      has_server_room_ready: false,
    },
    last_cdd_date_customer: "",
    remark: "",
  },
};
//
const MailerPage = () => {
  const params = useParams<{ id?: string }>();
  const [mailerHtml, setMailerHtml] = useState<string | undefined>(undefined);
  const [isPreviewLoading, setPreviewLoading] = useState(false);
  const [request, setRequest] = useState<CdnMailerRequestDto>({
    message: { template: "ISSUE" as CdnMailerRequestMessageTemplateEnum },
    project: {
      status: "ON_HOLD" as CdnMailerRequestProjectStatusEnum,
    },
  });

  const load = useCallback(async () => {
    const api = get_api();

    try {
      setPreviewLoading(true);
      const res = await api.cdnMailerBuildPost(request);
      setMailerHtml(res.data.html);
    } catch (e) {
      if (e.response) {
        const exc: AxiosError = e;
        const res = exc.response;

        if (res?.data.detail === "Language not found [value: .") {
          mailerFormikRef.current?.setErrors({
            message: { language: "Language is required" },
          });
        } else if (res?.data.detail) {
          const errors = {};

          res.data.detail.forEach((detail: any) => {
            // body.req van de loc afhalen, zodat we in een keer
            // de volledige name overhouden.
            const name = detail.loc.slice(2).join(".");

            // vervolgens gebruiken we dot.set om de foutmelding
            // op te slaan in de state, we gebruiken hierbij
            // simpelweg de foutmeldingsomschrijving van de api.
            dot.set(name, detail.msg, errors);
          });

          console.log(errors, "error messages");

          if (Object.keys(errors).length >= 0) {
            mailerFormikRef.current?.setErrors(errors);
          } else {
            console.error(errors);
          }
        }
      }
    }

    setPreviewLoading(false);
  }, [request]);

  useEffect(() => {
    load();
  }, [request, load]);

  function transferFormToRequest() {
    console.info("Show the formik data!", mailerFormikRef.current?.values);

    setRequest({
      foreign_id: mailerFormikRef.current?.values.foreign_id,
      from_address: mailerFormikRef.current?.values.from_address,
      reply_address: mailerFormikRef.current?.values.reply_address,
      expectation_video: mailerFormikRef.current?.values.expectation_video,
      debug_mode: mailerFormikRef.current?.values.debug_mode,
      deliver: mailerFormikRef.current?.values.deliver,
      to_address_set: mailerFormikRef.current?.values.to_address_set,
      cc_address_set: mailerFormikRef.current?.values.cc_address_set,
      bcc_address_set: mailerFormikRef.current?.values.bcc_address_set,
      attachment_set: mailerFormikRef.current?.values.attachment_set,
      message: {
        template: mailerFormikRef.current?.values.message.template!,
        introduction: mailerFormikRef.current?.values.message.introduction,
        language: mailerFormikRef.current?.values.message.language,
        addressee_name: mailerFormikRef.current?.values.message.addressee_name,
        addressee_email:
          mailerFormikRef.current?.values.message.addressee_email,
        subject: mailerFormikRef.current?.values.message.subject,
      },
      project: {
        status: mailerFormikRef.current?.values.project.status!,
        number: mailerFormikRef.current?.values.project.number,
        title: mailerFormikRef.current?.values.project.title,
        product: mailerFormikRef.current?.values.project.product,
        date_delivery_expected:
          mailerFormikRef.current?.values.project.date_delivery_expected,
        date_ordered: mailerFormikRef.current?.values.project.date_ordered,
        contractor: mailerFormikRef.current?.values.project.contractor,
        contact: mailerFormikRef.current?.values.project.contact,
        customer_code: mailerFormikRef.current?.values.project.customer_code,
        customer_name: mailerFormikRef.current?.values.project.customer_name,
        manager_code: mailerFormikRef.current?.values.project.manager_code,
        manager_name: mailerFormikRef.current?.values.project.manager_name,
        manager_email: mailerFormikRef.current?.values.project.manager_email,
        manager_phone: mailerFormikRef.current?.values.project.manager_phone,
        connection_set: mailerFormikRef.current?.values.project.connection_set,
      },
      progress: {
        has_nni: mailerFormikRef.current?.values.progress?.has_nni,
        has_pop: mailerFormikRef.current?.values.progress?.has_pop,
        has_loa: mailerFormikRef.current?.values.progress?.has_loa,
        has_turnaround_permit:
          mailerFormikRef.current?.values.progress?.has_turnaround_permit,
        has_executive_activities:
          mailerFormikRef.current?.values.progress?.has_executive_activities,
        last_cdd_date_customer:
          mailerFormikRef.current?.values.progress?.last_cdd_date_customer,
        remark: mailerFormikRef.current?.values.progress?.remark,
      },
    });
  }

  // region Send Mail

  const [sendOpen, setSendOpen] = useState(false);
  const onSendClose = useCallback(() => {
    setSendOpen(false);
    // reset form?
  }, []);

  const onSendClick = () => {
    transferFormToRequest(); // Deze doet setRequest, die hebben we nodig.
    setSendOpen(true);
  };

  // endregion

  const classes = useStyles();

  const mailerFormikRef = useRef<FormikProps<MailerFormData> | null>(null);

  const [mail, setMail] = useState<MailerFormData | null>(null);

  useEffect(() => {
    if (!params.id) {
      setMail(null);
    } else {
      const params_id = params.id;
      (async () => {
        setPreviewLoading(true);

        const res = await get_api().cdnMailerOnePost({
          id: parseInt(params_id),
        });

        setMail(res.data.request_json as MailerFormData);

        await sleep(250);

        transferFormToRequest();
        await load();
      })();
    }
  }, [params.id]);

  const initial = useMemo(() => {
    return mail || initialDefault;
  }, [mail]);

  const [values, setValues] = useState<any>(initial);

  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <Box p={2} className={classes.Module__Content}>
            <Formik<MailerFormData>
              innerRef={(p) => (mailerFormikRef.current = p)}
              initialValues={initial}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={() => {
                setValues(values);
              }}
            >
              {(props) => (
                <>
                  <Accordion className={classes.Module__Accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                    >
                      <h2 className={classes.Module__Title}>
                        General Settings
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MailerPageGeneralForm {...props} />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.Module__Accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                    >
                      <h2 className={classes.Module__Title}>Message Module</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MailerPageMessagesModuleForm {...props} />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.Module__Accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                    >
                      <h2 className={classes.Module__Title}>Project Module</h2>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "block" }}>
                      <MailerPageProjectModuleForm {...props} />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.Module__Accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                    >
                      <h2 className={classes.Module__Title}>Progress Module</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MailerPageProgressModuleForm {...props} />
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </Formik>
          </Box>
          <Grid
            container
            justifyContent="center"
            style={{
              boxShadow:
                "inset 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Grid
              item
              xs={4}
              style={{
                margin: "16px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                className={classes.Module__SendBtn}
                onClick={transferFormToRequest}
                disabled={isPreviewLoading}
              >
                Preview
              </Button>
              <Button
                fullWidth
                variant="contained"
                className={classes.Module__SendBtn}
                onClick={onSendClick}
                disabled={isPreviewLoading}
              >
                Send
              </Button>

              <SendDialog
                open={sendOpen}
                onClose={onSendClose}
                request={request}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.image}>
            {mailerHtml && (
              <iframe
                src={"data:text/html," + encodeURIComponent(mailerHtml)}
                width="100%"
                height="100%"
                frameBorder="0"
                title={"prev-window"}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

type SendDialogProps = {
  open: boolean;
  request?: CdnMailerRequestDto;
  onClose: () => void;
};

const SendDialog: FC<SendDialogProps> = (props) => {
  const { open, request, onClose } = props;
  const [sendResult, setSendResult] = useState<CdnMailerSendResponse | null>(
    null
  );
  const [busy, setBusy] = useState(false);

  const onSendClick = useCallback(async () => {
    setBusy(true);

    const res = await get_api().cdnMailerSendPost(request!);
    setSendResult(res.data);

    setBusy(false);
  }, [request]);

  const onCloseClick = useCallback(() => {
    setBusy(false);
    setSendResult(null);
    onClose();
  }, [onClose]);

  const addressSet = useMemo(() => {
    let result: string[] = [];
    request?.to_address_set?.forEach((a) => result.push(a.email));
    request?.cc_address_set?.forEach((a) => result.push(a.email));
    request?.bcc_address_set?.forEach((a) => result.push(a.email));
    return result;
  }, [request]);

  if (!request) return null;

  return (
    <Dialog open={open} onClose={onCloseClick}>
      <DialogTitle>Send e-mail</DialogTitle>
      <DialogContent>
        {sendResult ? (
          <DialogContentText>
            The e-mail has been sent, the id of the mail is:{" "}
            {sendResult.message_id}
          </DialogContentText>
        ) : (
          <>
            <DialogContentText>
              Do you want to send the e-mail to the following addresses?
            </DialogContentText>

            <List>
              {addressSet.map((email, ix) => (
                <ListItem key={ix}>
                  <ListItemIcon>
                    <Email />
                  </ListItemIcon>
                  <ListItemText primary={email} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {sendResult === null && (
          <Button
            onClick={onSendClick}
            type="submit"
            color="primary"
            disabled={busy}
            autoFocus
          >
            Send
          </Button>
        )}

        <Button onClick={onCloseClick} color="primary" disabled={busy}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MailerPage;

import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import sleep from "../../Ext/sleep";
import get_api from "../../Ext/get_api";
import { AxiosError } from "axios";

const useStyles = makeStyles((theme) => ({
  Dialog_SubmitBtnProgress: {
    position: "absolute",
    bottom: "0px",
    height: "4px",
    left: "0px",
    right: "0px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    backgroundColor: "transparent",

    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.primary.light,
      //   backgroundColor: "red",
    },
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  id: number;
  name: string;
  load: () => void;
};

type FormData = {
  name: string;
  is_archived: boolean;
};
const validationSchema = Yup.object({
  user: Yup.string().min(1),
});

const ManagementUserPageArchiveDialog: React.FC<Props> = (props) => {
  const classes = useStyles();
  const values = { name: props.name, is_archived: false };
  const onSubmit = async (data: FormData, actions: FormikHelpers<FormData>) => {
    const api = get_api();
    try {
      await sleep(1500);

      await api.crmPersonSettingsPost({
        id: props.id,
        is_archived: true,
      });

      actions.setSubmitting(false);
      props.load();
    } catch (e) {
      if (e.response) {
        const exc: AxiosError = e;
        const res = exc.response;
        console.log(res?.status, res?.data, res?.headers);
      } else {
        actions.setErrors({
          name: "Something went wrong",
        });
      }
    }
  };
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <Formik<FormData>
        initialValues={values}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(fp: FormikProps<FormData>) => {
          const {
            values: { name },
            errors,
            touched,
            handleSubmit,
            handleChange,
            isSubmitting,
          } = fp;
          return (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              style={{ height: "100%" }}
            >
              <Box padding={4}>
                <DialogTitle disableTypography>
                  <Typography variant="h4">Archive user</Typography>
                  <Typography variant="subtitle2">
                    Are you sure you want to archive this user?
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="name"
                        value={name}
                        helperText={touched.name ? !!errors.name : ""}
                        error={!!errors.name}
                        onChange={handleChange}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button variant="outlined" onClick={() => props.onClose()}>
                      No
                    </Button>
                    <Button
                      type="submit"
                      variant="outlined"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && (
                        <LinearProgress
                          className={classes.Dialog_SubmitBtnProgress}
                        />
                      )}
                      Yes
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ManagementUserPageArchiveDialog;

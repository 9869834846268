import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";

import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import ManagementLanguagePageDataRows from "./ManagementLanguagePageDataRows";
import get_api from "../../Ext/get_api";
import { AppI11nManyResponse } from "../../api";
import _ from "underscore";

const useStyles = makeStyles((theme) => ({
  Table__Content: {
    height: "calc(100vh - 50px)",
    overflow: "auto",
  },

  Table__TableHeaders: {
    "& .MuiTableCell-stickyHeader": {
      fontWeight: "bold",
      backgroundColor: theme.palette.primary.dark,
      color: "#fff",
      fontSize: "20px",
      padding: "16px",
    },
  },
  Table_TableBody: {
    border: "1px solid #C0C0C0",
  },
}));

const ManagementLanguagePage = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [language_set, setLanguageset] = useState<
    AppI11nManyResponse["language_set"]
  >([]);
  const [localization_set, setLocalizationset] = useState<
    AppI11nManyResponse["localization_set"]
  >([]);
  const [translation_set, setTranslationset] = useState<
    AppI11nManyResponse["translation_set"]
  >([]);
  const [search, setSearch] = useState("");

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const load = useCallback(async () => {
    const api = get_api();
    const als_res = await api.appI11nManyPost();

    setLanguageset(als_res.data.language_set);
    setLocalizationset(als_res.data.localization_set);
    setTranslationset(als_res.data.translation_set);
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const debounce = _.debounce((_searchVal: string) => {
    setSearch(_searchVal);
  }, 500);

  const handleSearch = (event: any) => {
    debounce(event.target.value);
  };

  return (
    <div>
      <Box
        paddingTop={2}
        pl={2}
        pr={2}
        paddingBottom={2}
        className={classes.Table__Content}
      >
        <Paper>
          <Box ml={2} mr={2}>
            <Grid container>
              <Grid item xs>
                <h2>Language Info</h2>
              </Grid>
              <Grid item>
                <TextField
                  label="Search"
                  onChange={handleSearch}
                  InputProps={{
                    endAdornment: (
                      <SearchOutlinedIcon style={{ marginRight: "25px" }} />
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <TableContainer>
            <Table stickyHeader className={classes.Table__TableHeaders}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Identifier</TableCell>
                  <TableCell align="left">UpdatedAt</TableCell>
                  {language_set.map((lan, index) => (
                    <Fragment key={index}>
                      <TableCell align="left">{lan.name}</TableCell>
                      <TableCell>{/* <EditIcon /> */}</TableCell>
                    </Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.Table_TableBody}>
                {localization_set
                  .filter((localization) => {
                    return translation_set
                      .filter((x) => x.localization_id === localization.id)

                      .some((ix) => {
                        return ix.value
                          .toLocaleLowerCase()
                          .includes(search.toLocaleLowerCase());
                      });
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((localization) => {
                    // We willen per localization een row, met daar in de titel en alle vertalingen per taal.
                    // De vertalingen halen we op, we doen dat voor alle talen, maar mogen uiteraard alleen "deze",
                    // localization zijn.
                    const filtered_translation_set = language_set.map(
                      (language) => {
                        const translation = translation_set.find(
                          (translation) =>
                            translation.language_id === language.id &&
                            translation.localization_id === localization.id
                        );

                        return (
                          translation || {
                            id: -1,
                            language_id: language.id,
                            localization_id: localization.id,
                            updated_at: undefined,
                            value: "...",
                          }
                        );
                      }
                    );

                    // Vervolgens moeten we ze even sorteren, anders staat de Franse waarde onder Nederlands,
                    // omdat ze niet allemaal in de zelfde orde zijn aangemaakt (soms Nederlands als eerste, soms
                    // Engels als eerste).
                    const sorted_translation_set =
                      filtered_translation_set.sort(
                        (a, b) =>
                          language_set.findIndex(
                            (y) => y.id === a.language_id
                          ) -
                          language_set.findIndex((y) => y.id === b.language_id)
                      );

                    return (
                      <ManagementLanguagePageDataRows
                        key={localization.id}
                        id={localization.id}
                        title={localization.value}
                        translation_set={sorted_translation_set}
                        load={() => load()}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <Box p={2}>
            <TablePagination
              component="div"
              count={localization_set.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default ManagementLanguagePage;

/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface AppI11nManyResponse
 */
export interface AppI11nManyResponse {
  /**
   *
   * @type {Array<AppLanguageDto>}
   * @memberof AppI11nManyResponse
   */
  language_set: Array<AppLanguageDto>;
  /**
   *
   * @type {Array<AppLocalizationDto>}
   * @memberof AppI11nManyResponse
   */
  localization_set: Array<AppLocalizationDto>;
  /**
   *
   * @type {Array<AppTranslationDto>}
   * @memberof AppI11nManyResponse
   */
  translation_set: Array<AppTranslationDto>;
}
/**
 *
 * @export
 * @interface AppI11nSaveRequest
 */
export interface AppI11nSaveRequest {
  /**
   *
   * @type {number}
   * @memberof AppI11nSaveRequest
   */
  language_id: number;
  /**
   *
   * @type {number}
   * @memberof AppI11nSaveRequest
   */
  localization_id: number;
  /**
   *
   * @type {string}
   * @memberof AppI11nSaveRequest
   */
  value: string;
}
/**
 *
 * @export
 * @interface AppLanguageDto
 */
export interface AppLanguageDto {
  /**
   *
   * @type {number}
   * @memberof AppLanguageDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AppLanguageDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AppLanguageDto
   */
  iso2: string;
}
/**
 *
 * @export
 * @interface AppLocalizationDto
 */
export interface AppLocalizationDto {
  /**
   *
   * @type {number}
   * @memberof AppLocalizationDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AppLocalizationDto
   */
  value: string;
}
/**
 *
 * @export
 * @interface AppTranslationDto
 */
export interface AppTranslationDto {
  /**
   *
   * @type {number}
   * @memberof AppTranslationDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof AppTranslationDto
   */
  language_id: number;
  /**
   *
   * @type {number}
   * @memberof AppTranslationDto
   */
  localization_id: number;
  /**
   *
   * @type {string}
   * @memberof AppTranslationDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof AppTranslationDto
   */
  value: string;
}
/**
 *
 * @export
 * @interface CdnMailerAttachmentDto
 */
export interface CdnMailerAttachmentDto {
  /**
   * Name of the file, eg. `project-1234.xlsx`.
   * @type {string}
   * @memberof CdnMailerAttachmentDto
   */
  name: string;
  /**
   * Description of the file, eg. `SiteSurvey 20 nov 2020`, optional.
   * @type {string}
   * @memberof CdnMailerAttachmentDto
   */
  description?: string;
  /**
   * Base64 encoded content of the file.
   * @type {string}
   * @memberof CdnMailerAttachmentDto
   */
  content: string;
  /**
   * Content-type of the file, when (null) Excite will try to determine it, optional.
   * @type {string}
   * @memberof CdnMailerAttachmentDto
   */
  content_type?: string;
}
/**
 * The HTML build by Excite, it can be used to send the e-mail yourself, or, to display it in an frontend (Excite).  Warnings:  - The passed attachments are returned in full.
 * @export
 * @interface CdnMailerBuildResponse
 */
export interface CdnMailerBuildResponse {
  /**
   *
   * @type {CdnMailerServiceAddressDto}
   * @memberof CdnMailerBuildResponse
   */
  from_address: CdnMailerServiceAddressDto;
  /**
   *
   * @type {CdnMailerServiceAddressDto}
   * @memberof CdnMailerBuildResponse
   */
  reply_to_address?: CdnMailerServiceAddressDto;
  /**
   *
   * @type {Array<CdnMailerServiceAddressDto>}
   * @memberof CdnMailerBuildResponse
   */
  to_address_set?: Array<CdnMailerServiceAddressDto>;
  /**
   *
   * @type {Array<CdnMailerServiceAddressDto>}
   * @memberof CdnMailerBuildResponse
   */
  cc_address_set?: Array<CdnMailerServiceAddressDto>;
  /**
   *
   * @type {Array<CdnMailerServiceAddressDto>}
   * @memberof CdnMailerBuildResponse
   */
  bcc_address_set?: Array<CdnMailerServiceAddressDto>;
  /**
   *
   * @type {string}
   * @memberof CdnMailerBuildResponse
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerBuildResponse
   */
  html?: string;
  /**
   *
   * @type {Array<CdnMailerServiceAttachmentDto>}
   * @memberof CdnMailerBuildResponse
   */
  attachment_set?: Array<CdnMailerServiceAttachmentDto>;
  /**
   *
   * @type {string}
   * @memberof CdnMailerBuildResponse
   */
  event_payload?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerBuildResponse
   */
  custom_id?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerBuildResponse
   */
  filename: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CdnMailerManyOrderByEnum {
  NewestFirst = "NEWEST_FIRST",
  OldestFirst = "OLDEST_FIRST",
}

/**
 *
 * @export
 * @interface CdnMailerManyRequest
 */
export interface CdnMailerManyRequest {
  /**
   *
   * @type {string}
   * @memberof CdnMailerManyRequest
   */
  search?: string;
  /**
   *
   * @type {CdnMailerRequestMessageTemplateEnum}
   * @memberof CdnMailerManyRequest
   */
  template?: CdnMailerRequestMessageTemplateEnum;
  /**
   *
   * @type {CdnMailerManyOrderByEnum}
   * @memberof CdnMailerManyRequest
   */
  order_by?: CdnMailerManyOrderByEnum;
  /**
   *
   * @type {string}
   * @memberof CdnMailerManyRequest
   */
  created_after?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerManyRequest
   */
  created_before?: string;
  /**
   *
   * @type {number}
   * @memberof CdnMailerManyRequest
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof CdnMailerManyRequest
   */
  amount: number;
}
/**
 *
 * @export
 * @interface CdnMailerManyResponse
 */
export interface CdnMailerManyResponse {
  /**
   *
   * @type {Array<CdnMailerOneResponse>}
   * @memberof CdnMailerManyResponse
   */
  record_set: Array<CdnMailerOneResponse>;
  /**
   *
   * @type {number}
   * @memberof CdnMailerManyResponse
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof CdnMailerManyResponse
   */
  amount: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CdnMailerMessageStatusEnum {
  Setup = "SETUP",
  Failed = "FAILED",
  Sent = "SENT",
}

/**
 *
 * @export
 * @interface CdnMailerOneRequest
 */
export interface CdnMailerOneRequest {
  /**
   *
   * @type {number}
   * @memberof CdnMailerOneRequest
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CdnMailerOneRequest
   */
  foreign_id?: string;
}
/**
 *
 * @export
 * @interface CdnMailerOneResponse
 */
export interface CdnMailerOneResponse {
  /**
   *
   * @type {number}
   * @memberof CdnMailerOneResponse
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CdnMailerOneResponse
   */
  foreign_id?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerOneResponse
   */
  created_at: string;
  /**
   *
   * @type {CdnMailerMessageStatusEnum}
   * @memberof CdnMailerOneResponse
   */
  status: CdnMailerMessageStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CdnMailerOneResponse
   */
  from_email: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerOneResponse
   */
  reply_email?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerOneResponse
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerOneResponse
   */
  project_number?: string;
  /**
   *
   * @type {CdnMailerRequestDto}
   * @memberof CdnMailerOneResponse
   */
  request_json: CdnMailerRequestDto;
  /**
   *
   * @type {string}
   * @memberof CdnMailerOneResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface CdnMailerRequestAddresseeDto
 */
export interface CdnMailerRequestAddresseeDto {
  /**
   * The name part of the e-mailaddress, optional.
   * @type {string}
   * @memberof CdnMailerRequestAddresseeDto
   */
  name?: string;
  /**
   * The e-mail part of the e-mailaddress, automatically stripped of whitespace and lower-cased.
   * @type {string}
   * @memberof CdnMailerRequestAddresseeDto
   */
  email: string;
}
/**
 *
 * @export
 * @interface CdnMailerRequestConnectionDto
 */
export interface CdnMailerRequestConnectionDto {
  /**
   *
   * @type {CdnMailerRequestConnectionStatusEnum}
   * @memberof CdnMailerRequestConnectionDto
   */
  status: CdnMailerRequestConnectionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestConnectionDto
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestConnectionDto
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestConnectionDto
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestConnectionDto
   */
  date_delivery_expected?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestConnectionDto
   */
  date_delivery?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestConnectionDto
   */
  date_delivery_changed?: string;
  /**
   *
   * @type {Array<CdnMailerRequestMilestoneDto>}
   * @memberof CdnMailerRequestConnectionDto
   */
  milestone_set?: Array<CdnMailerRequestMilestoneDto>;
  /**
   *
   * @type {Array<CdnMailerRequestIssueDto>}
   * @memberof CdnMailerRequestConnectionDto
   */
  issue_set?: Array<CdnMailerRequestIssueDto>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CdnMailerRequestConnectionStatusEnum {
  OnHold = "ON_HOLD",
  Preparation = "PREPARATION",
  Engineering = "ENGINEERING",
  Execution = "EXECUTION",
  Delivery = "DELIVERY",
}

/**
 * The mailer request data-transfer-object, used to build the e-mail.
 * @export
 * @interface CdnMailerRequestDto
 */
export interface CdnMailerRequestDto {
  /**
   * A foreign identifier, can be used for debugging failed requests, optional.
   * @type {string}
   * @memberof CdnMailerRequestDto
   */
  foreign_id?: string;
  /**
   * Used as the from-addressee in the mail, when not filled we use info@eurofiber.com, optional.
   * @type {CdnMailerRequestAddresseeDto}
   * @memberof CdnMailerRequestDto
   */
  from_address?: CdnMailerRequestAddresseeDto;
  /**
   * Used as the reply-to-address in the mail, when not filled we skip this header, optional.
   * @type {CdnMailerRequestAddresseeDto}
   * @memberof CdnMailerRequestDto
   */
  reply_address?: CdnMailerRequestAddresseeDto;
  /**
   * A list of to-addressees, optional.
   * @type {Array<CdnMailerRequestAddresseeDto>}
   * @memberof CdnMailerRequestDto
   */
  to_address_set?: Array<CdnMailerRequestAddresseeDto>;
  /**
   * A list of cc-addressees, optional.
   * @type {Array<CdnMailerRequestAddresseeDto>}
   * @memberof CdnMailerRequestDto
   */
  cc_address_set?: Array<CdnMailerRequestAddresseeDto>;
  /**
   * A list of bcc-addressees, optional.
   * @type {Array<CdnMailerRequestAddresseeDto>}
   * @memberof CdnMailerRequestDto
   */
  bcc_address_set?: Array<CdnMailerRequestAddresseeDto>;
  /**
   * The message data-transfer-object, used to determine what to send.
   * @type {CdnMailerRequestMessageDto}
   * @memberof CdnMailerRequestDto
   */
  message: CdnMailerRequestMessageDto;
  /**
   * Data-transfer-object of an Eurofiber project.
   * @type {CdnMailerRequestProjectDto}
   * @memberof CdnMailerRequestDto
   */
  project?: CdnMailerRequestProjectDto;
  /**
   *
   * @type {CdnMailerRequestProgressDto}
   * @memberof CdnMailerRequestDto
   */
  progress?: CdnMailerRequestProgressDto;
  /**
   * A list of zero or more attachments to be included in the e-mail.
   * @type {Array<CdnMailerAttachmentDto>}
   * @memberof CdnMailerRequestDto
   */
  attachment_set?: Array<CdnMailerAttachmentDto>;
  /**
   * Set to true to include the expectation-video.
   * @type {boolean}
   * @memberof CdnMailerRequestDto
   */
  expectation_video?: boolean;
  /**
   * Set to true to include an JSON attachment with the request.
   * @type {boolean}
   * @memberof CdnMailerRequestDto
   */
  debug_mode?: boolean;
  /**
   * Actually deliver the message (defaults to FALSE for now).
   * @type {boolean}
   * @memberof CdnMailerRequestDto
   */
  deliver?: boolean;
}
/**
 *
 * @export
 * @interface CdnMailerRequestIssueDto
 */
export interface CdnMailerRequestIssueDto {
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestIssueDto
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestIssueDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestIssueDto
   */
  explanation?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestIssueDto
   */
  action_holder?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestIssueDto
   */
  issue_type?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestIssueDto
   */
  date_start?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestIssueDto
   */
  date_update?: string;
}
/**
 *
 * @export
 * @interface CdnMailerRequestMessageDto
 */
export interface CdnMailerRequestMessageDto {
  /**
   * Language of the mail, used for automatic translations.
   * @type {string}
   * @memberof CdnMailerRequestMessageDto
   */
  language?: string;
  /**
   * Template to be used, selects specific designed blocks and used for images and translations.
   * @type {CdnMailerRequestMessageTemplateEnum}
   * @memberof CdnMailerRequestMessageDto
   */
  template: CdnMailerRequestMessageTemplateEnum;
  /**
   * The person to whom the mail is addressed, optional.
   * @type {string}
   * @memberof CdnMailerRequestMessageDto
   */
  addressee_name?: string;
  /**
   * Used to mark the sent mail for higher priority, optional.
   * @type {string}
   * @memberof CdnMailerRequestMessageDto
   */
  addressee_email?: string;
  /**
   * Subject of the message, leave (null) for automatic value (translated) based on the request.
   * @type {string}
   * @memberof CdnMailerRequestMessageDto
   */
  subject?: string;
  /**
   * Introduction of the message, leave (null) for automatic value (translated) based on the request.
   * @type {string}
   * @memberof CdnMailerRequestMessageDto
   */
  introduction?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CdnMailerRequestMessageTemplateEnum {
  Simple = "SIMPLE",
  Issue = "ISSUE",
  Progression = "PROGRESSION",
  ResearchInvite = "RESEARCH_INVITE",
  OrderConfirmation = "ORDER_CONFIRMATION",
  SisuOrRfo = "SISU_OR_RFO",
  Rfo = "RFO",
  RfoChange = "RFO_CHANGE",
  Rfs = "RFS",
}

/**
 *
 * @export
 * @interface CdnMailerRequestMilestoneDto
 */
export interface CdnMailerRequestMilestoneDto {
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestMilestoneDto
   */
  value: string;
}
/**
 *
 * @export
 * @interface CdnMailerRequestProgressDto
 */
export interface CdnMailerRequestProgressDto {
  /**
   *
   * @type {boolean}
   * @memberof CdnMailerRequestProgressDto
   */
  has_nni?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CdnMailerRequestProgressDto
   */
  has_pop?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CdnMailerRequestProgressDto
   */
  has_loa?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CdnMailerRequestProgressDto
   */
  has_turnaround_permit?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CdnMailerRequestProgressDto
   */
  has_executive_activities?: boolean;
  /**
   *
   * @type {CdnMailerRequestProgressNniDto}
   * @memberof CdnMailerRequestProgressDto
   */
  nni?: CdnMailerRequestProgressNniDto;
  /**
   *
   * @type {CdnMailerRequestProgressPopDto}
   * @memberof CdnMailerRequestProgressDto
   */
  pop?: CdnMailerRequestProgressPopDto;
  /**
   *
   * @type {CdnMailerRequestProgressTurnaroundPermitDto}
   * @memberof CdnMailerRequestProgressDto
   */
  turnaround_permit?: CdnMailerRequestProgressTurnaroundPermitDto;
  /**
   *
   * @type {CdnMailerRequestProgressExecutiveActivitiesDto}
   * @memberof CdnMailerRequestProgressDto
   */
  executive_activities?: CdnMailerRequestProgressExecutiveActivitiesDto;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressDto
   */
  last_cdd_date_customer?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressDto
   */
  remark?: string;
}
/**
 *
 * @export
 * @interface CdnMailerRequestProgressExecutiveActivitiesDto
 */
export interface CdnMailerRequestProgressExecutiveActivitiesDto {
  /**
   *
   * @type {number}
   * @memberof CdnMailerRequestProgressExecutiveActivitiesDto
   */
  weeks?: number;
  /**
   *
   * @type {boolean}
   * @memberof CdnMailerRequestProgressExecutiveActivitiesDto
   */
  has_controlled_drilling?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CdnMailerRequestProgressExecutiveActivitiesDto
   */
  has_dependency_project?: boolean;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressExecutiveActivitiesDto
   */
  project_number?: string;
  /**
   *
   * @type {boolean}
   * @memberof CdnMailerRequestProgressExecutiveActivitiesDto
   */
  has_top_10_weld?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CdnMailerRequestProgressExecutiveActivitiesDto
   */
  has_server_room_ready?: boolean;
}
/**
 *
 * @export
 * @interface CdnMailerRequestProgressNniDto
 */
export interface CdnMailerRequestProgressNniDto {
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressNniDto
   */
  network_type_element?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressNniDto
   */
  vlan_range?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressNniDto
   */
  cabinet_id?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressNniDto
   */
  network_element?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressNniDto
   */
  gate_position?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressNniDto
   */
  transceiver?: string;
}
/**
 *
 * @export
 * @interface CdnMailerRequestProgressPopDto
 */
export interface CdnMailerRequestProgressPopDto {
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressPopDto
   */
  cabinet_id?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressPopDto
   */
  network_element?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressPopDto
   */
  gate_position?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProgressPopDto
   */
  transceiver?: string;
}
/**
 *
 * @export
 * @interface CdnMailerRequestProgressTurnaroundPermitDto
 */
export interface CdnMailerRequestProgressTurnaroundPermitDto {
  /**
   *
   * @type {number}
   * @memberof CdnMailerRequestProgressTurnaroundPermitDto
   */
  weeks?: number;
  /**
   *
   * @type {boolean}
   * @memberof CdnMailerRequestProgressTurnaroundPermitDto
   */
  has_soil_survey?: boolean;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CdnMailerRequestProjectCCREnum {
  GenericMessage = "GENERIC_MESSAGE",
  LowestStatus = "LOWEST_STATUS",
  HighestStatus = "HIGHEST_STATUS",
}

/**
 *
 * @export
 * @interface CdnMailerRequestProjectDto
 */
export interface CdnMailerRequestProjectDto {
  /**
   * Primary status of the project, used for the setup of the e-mail message.
   * @type {CdnMailerRequestProjectStatusEnum}
   * @memberof CdnMailerRequestProjectDto
   */
  status: CdnMailerRequestProjectStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  product?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  date_delivery_expected?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  date_ordered?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  customer_requested_delivery_date?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  contractor?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  contact?: string;
  /**
   * Customer reference, code in their system.
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  customer_reference?: string;
  /**
   * Not used yet, to be used to gather all customer information automatically.
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  customer_code?: string;
  /**
   * Name of the customer.
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  customer_name?: string;
  /**
   * Not used yet, to be used to gather all manager information automatically.
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  manager_code?: string;
  /**
   * Used in the signature of the e-mail message, defaults to Eurofiber.
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  manager_name?: string;
  /**
   * Used in the footer of the e-mail message, defaults to the generic data.
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  manager_email?: string;
  /**
   * Used in the footer of the e-mail message, defaults to the generic data.
   * @type {string}
   * @memberof CdnMailerRequestProjectDto
   */
  manager_phone?: string;
  /**
   * Used to override the project status, when (null) Excite will determine if this is truthy.
   * @type {boolean}
   * @memberof CdnMailerRequestProjectDto
   */
  conflicting_connection_status?: boolean;
  /**
   * Used to determine how to resolve the conflicting status override for the project.
   * @type {CdnMailerRequestProjectCCREnum}
   * @memberof CdnMailerRequestProjectDto
   */
  conflicting_connection_resolve?: CdnMailerRequestProjectCCREnum;
  /**
   *
   * @type {Array<CdnMailerRequestConnectionDto>}
   * @memberof CdnMailerRequestProjectDto
   */
  connection_set?: Array<CdnMailerRequestConnectionDto>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CdnMailerRequestProjectStatusEnum {
  OnHold = "ON_HOLD",
  Preparation = "PREPARATION",
  Engineering = "ENGINEERING",
  Execution = "EXECUTION",
  Delivery = "DELIVERY",
}

/**
 *
 * @export
 * @interface CdnMailerSendResponse
 */
export interface CdnMailerSendResponse {
  /**
   * The message identifier.
   * @type {number}
   * @memberof CdnMailerSendResponse
   */
  message_id: number;
  /**
   * A list of all addresses and their connected information.
   * @type {Array<CdnMailerSendResponseRecipientDto>}
   * @memberof CdnMailerSendResponse
   */
  recipient_set: Array<CdnMailerSendResponseRecipientDto>;
}
/**
 *
 * @export
 * @interface CdnMailerSendResponseRecipientDto
 */
export interface CdnMailerSendResponseRecipientDto {
  /**
   * The delivery status identifier for one addressee.
   * @type {number}
   * @memberof CdnMailerSendResponseRecipientDto
   */
  recipient_id: number;
  /**
   * The e-mailaddress to which the `mailing_id` is connected.
   * @type {string}
   * @memberof CdnMailerSendResponseRecipientDto
   */
  email: string;
}
/**
 *
 * @export
 * @interface CdnMailerServiceAddressDto
 */
export interface CdnMailerServiceAddressDto {
  /**
   *
   * @type {string}
   * @memberof CdnMailerServiceAddressDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerServiceAddressDto
   */
  email: string;
}
/**
 *
 * @export
 * @interface CdnMailerServiceAttachmentDto
 */
export interface CdnMailerServiceAttachmentDto {
  /**
   *
   * @type {string}
   * @memberof CdnMailerServiceAttachmentDto
   */
  content_type: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerServiceAttachmentDto
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof CdnMailerServiceAttachmentDto
   */
  content: string;
}
/**
 *
 * @export
 * @interface CrmPersonAuthenticateRequest
 */
export interface CrmPersonAuthenticateRequest {
  /**
   *
   * @type {string}
   * @memberof CrmPersonAuthenticateRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonAuthenticateRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface CrmPersonAuthenticateResponse
 */
export interface CrmPersonAuthenticateResponse {
  /**
   *
   * @type {CrmPersonBaseDto}
   * @memberof CrmPersonAuthenticateResponse
   */
  person: CrmPersonBaseDto;
  /**
   *
   * @type {string}
   * @memberof CrmPersonAuthenticateResponse
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonAuthenticateResponse
   */
  expires_at: string;
}
/**
 *
 * @export
 * @interface CrmPersonBaseDto
 */
export interface CrmPersonBaseDto {
  /**
   *
   * @type {number}
   * @memberof CrmPersonBaseDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonBaseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonBaseDto
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonBaseDto
   */
  is_archived: boolean;
}
/**
 *
 * @export
 * @interface CrmPersonDto
 */
export interface CrmPersonDto {
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonDto
   */
  is_archived: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonDto
   */
  has_password: boolean;
}
/**
 *
 * @export
 * @interface CrmPersonManyRequest
 */
export interface CrmPersonManyRequest {
  /**
   *
   * @type {number}
   * @memberof CrmPersonManyRequest
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof CrmPersonManyRequest
   */
  limit?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonManyRequest
   */
  search?: string;
}
/**
 *
 * @export
 * @interface CrmPersonManyResponse
 */
export interface CrmPersonManyResponse {
  /**
   *
   * @type {GenericPagination}
   * @memberof CrmPersonManyResponse
   */
  pagination: GenericPagination;
  /**
   *
   * @type {Array<CrmPersonDto>}
   * @memberof CrmPersonManyResponse
   */
  records: Array<CrmPersonDto>;
}
/**
 *
 * @export
 * @interface CrmPersonOneRequest
 */
export interface CrmPersonOneRequest {
  /**
   *
   * @type {number}
   * @memberof CrmPersonOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface CrmPersonResetPasswordCodeDto
 */
export interface CrmPersonResetPasswordCodeDto {
  /**
   *
   * @type {string}
   * @memberof CrmPersonResetPasswordCodeDto
   */
  email: string;
}
/**
 *
 * @export
 * @interface CrmPersonResetPasswordCodeResponse
 */
export interface CrmPersonResetPasswordCodeResponse {
  /**
   *
   * @type {number}
   * @memberof CrmPersonResetPasswordCodeResponse
   */
  code_id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonResetPasswordCodeResponse
   */
  code_token: string;
}
/**
 *
 * @export
 * @interface CrmPersonResetPasswordDto
 */
export interface CrmPersonResetPasswordDto {
  /**
   *
   * @type {string}
   * @memberof CrmPersonResetPasswordDto
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonResetPasswordDto
   */
  code_id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonResetPasswordDto
   */
  code_token: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonResetPasswordDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface CrmPersonSaveRequest
 */
export interface CrmPersonSaveRequest {
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  name: string;
}
/**
 * A simple detail-response, the detail is always one of the following options:  - `OK`: the request was processed. - `NOT_FOUND`: the requested request was not found.  - `ACCOUNT_NO_PASSWORD`: the account you have reached has no password setup. - `ACCOUNT_PASSWORD_INVALID`: the password you tried to verify does not match.  Something, things go bad, these are the details that tell you we could not handle it:  - `NOK`: dead machine-state reached, this should not occur. - `NI`: not-implemented, we haven\'t had the time to implement this feature / option yet.
 * @export
 * @interface CrmPersonSaveResponse
 */
export interface CrmPersonSaveResponse {
  /**
   *
   * @type {DetailEnum}
   * @memberof CrmPersonSaveResponse
   */
  detail: DetailEnum;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveResponse
   */
  id: number;
}
/**
 *
 * @export
 * @interface CrmPersonSettingsRequest
 */
export interface CrmPersonSettingsRequest {
  /**
   *
   * @type {number}
   * @memberof CrmPersonSettingsRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSettingsRequest
   */
  password?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonSettingsRequest
   */
  is_archived?: boolean;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum DetailEnum {
  Ok = "OK",
  Nok = "NOK",
  Ni = "NI",
  NotFound = "NOT_FOUND",
  Expired = "EXPIRED",
  Duplicate = "DUPLICATE",
  AccountNoPassword = "ACCOUNT_NO_PASSWORD",
  AccountPasswordInvalid = "ACCOUNT_PASSWORD_INVALID",
  TokenInvalid = "TOKEN_INVALID",
  PasswordPolicy = "PASSWORD_POLICY",
}

/**
 * A simple detail-response, the detail is always one of the following options:  - `OK`: the request was processed. - `NOT_FOUND`: the requested request was not found.  - `ACCOUNT_NO_PASSWORD`: the account you have reached has no password setup. - `ACCOUNT_PASSWORD_INVALID`: the password you tried to verify does not match.  Something, things go bad, these are the details that tell you we could not handle it:  - `NOK`: dead machine-state reached, this should not occur. - `NI`: not-implemented, we haven\'t had the time to implement this feature / option yet.
 * @export
 * @interface DetailResponse
 */
export interface DetailResponse {
  /**
   *
   * @type {DetailEnum}
   * @memberof DetailResponse
   */
  detail: DetailEnum;
}
/**
 *
 * @export
 * @interface GenericPagination
 */
export interface GenericPagination {
  /**
   *
   * @type {number}
   * @memberof GenericPagination
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof GenericPagination
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof GenericPagination
   */
  count: number;
}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<string>}
   * @memberof ValidationError
   */
  loc: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary App I11N Many Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appI11nManyPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/app/i11n/many`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary App I11N Save Route
     * @param {AppI11nSaveRequest} appI11nSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appI11nSavePost: async (
      appI11nSaveRequest: AppI11nSaveRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'appI11nSaveRequest' is not null or undefined
      assertParamExists(
        "appI11nSavePost",
        "appI11nSaveRequest",
        appI11nSaveRequest
      );
      const localVarPath = `/app/i11n/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        appI11nSaveRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cdn Mailer Build Route
     * @param {CdnMailerRequestDto} cdnMailerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdnMailerBuildPost: async (
      cdnMailerRequestDto: CdnMailerRequestDto,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdnMailerRequestDto' is not null or undefined
      assertParamExists(
        "cdnMailerBuildPost",
        "cdnMailerRequestDto",
        cdnMailerRequestDto
      );
      const localVarPath = `/cdn/mailer/build`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cdnMailerRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cdn Mailer Html Route
     * @param {CdnMailerRequestDto} cdnMailerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdnMailerHtmlPost: async (
      cdnMailerRequestDto: CdnMailerRequestDto,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdnMailerRequestDto' is not null or undefined
      assertParamExists(
        "cdnMailerHtmlPost",
        "cdnMailerRequestDto",
        cdnMailerRequestDto
      );
      const localVarPath = `/cdn/mailer/html`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cdnMailerRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cdn Mailer Many Route
     * @param {CdnMailerManyRequest} cdnMailerManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdnMailerManyPost: async (
      cdnMailerManyRequest: CdnMailerManyRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdnMailerManyRequest' is not null or undefined
      assertParamExists(
        "cdnMailerManyPost",
        "cdnMailerManyRequest",
        cdnMailerManyRequest
      );
      const localVarPath = `/cdn/mailer/many`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cdnMailerManyRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cdn Mailer One Route
     * @param {CdnMailerOneRequest} cdnMailerOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdnMailerOnePost: async (
      cdnMailerOneRequest: CdnMailerOneRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdnMailerOneRequest' is not null or undefined
      assertParamExists(
        "cdnMailerOnePost",
        "cdnMailerOneRequest",
        cdnMailerOneRequest
      );
      const localVarPath = `/cdn/mailer/one`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cdnMailerOneRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *  Send an message to one or more recipients. Excite will automatically setup the message based on the passed request. A response containing a set of identifiers is returned, which can be used to check the status of the message.  **Warnings:**  - A minimum of one address is required, any of the options (to, cc, or bcc). - An invalid e-mailaddress will fail the request. - All the provided e-mailaddresses _must_ be unique (to, cc, or bcc). - The message must never weigh more than 15Mb (attachments).
     * @summary Cdn Mailer Send Route
     * @param {CdnMailerRequestDto} cdnMailerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdnMailerSendPost: async (
      cdnMailerRequestDto: CdnMailerRequestDto,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdnMailerRequestDto' is not null or undefined
      assertParamExists(
        "cdnMailerSendPost",
        "cdnMailerRequestDto",
        cdnMailerRequestDto
      );
      const localVarPath = `/cdn/mailer/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cdnMailerRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Authenticate Route
     * @param {CrmPersonAuthenticateRequest} crmPersonAuthenticateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonAuthenticatePost: async (
      crmPersonAuthenticateRequest: CrmPersonAuthenticateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonAuthenticateRequest' is not null or undefined
      assertParamExists(
        "crmPersonAuthenticatePost",
        "crmPersonAuthenticateRequest",
        crmPersonAuthenticateRequest
      );
      const localVarPath = `/crm/person/authenticate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        crmPersonAuthenticateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Many Route
     * @param {CrmPersonManyRequest} crmPersonManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonManyPost: async (
      crmPersonManyRequest: CrmPersonManyRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonManyRequest' is not null or undefined
      assertParamExists(
        "crmPersonManyPost",
        "crmPersonManyRequest",
        crmPersonManyRequest
      );
      const localVarPath = `/crm/person/many`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        crmPersonManyRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person One Route
     * @param {CrmPersonOneRequest} crmPersonOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonOnePost: async (
      crmPersonOneRequest: CrmPersonOneRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonOneRequest' is not null or undefined
      assertParamExists(
        "crmPersonOnePost",
        "crmPersonOneRequest",
        crmPersonOneRequest
      );
      const localVarPath = `/crm/person/one`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        crmPersonOneRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Reset Password Code Route
     * @param {CrmPersonResetPasswordCodeDto} crmPersonResetPasswordCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonResetPasswordCodePost: async (
      crmPersonResetPasswordCodeDto: CrmPersonResetPasswordCodeDto,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonResetPasswordCodeDto' is not null or undefined
      assertParamExists(
        "crmPersonResetPasswordCodePost",
        "crmPersonResetPasswordCodeDto",
        crmPersonResetPasswordCodeDto
      );
      const localVarPath = `/crm/person/reset_password_code`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        crmPersonResetPasswordCodeDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Reset Password Route
     * @param {CrmPersonResetPasswordDto} crmPersonResetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonResetPasswordPost: async (
      crmPersonResetPasswordDto: CrmPersonResetPasswordDto,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonResetPasswordDto' is not null or undefined
      assertParamExists(
        "crmPersonResetPasswordPost",
        "crmPersonResetPasswordDto",
        crmPersonResetPasswordDto
      );
      const localVarPath = `/crm/person/reset_password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        crmPersonResetPasswordDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Save Route
     * @param {CrmPersonSaveRequest} crmPersonSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonSavePost: async (
      crmPersonSaveRequest: CrmPersonSaveRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonSaveRequest' is not null or undefined
      assertParamExists(
        "crmPersonSavePost",
        "crmPersonSaveRequest",
        crmPersonSaveRequest
      );
      const localVarPath = `/crm/person/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        crmPersonSaveRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Session Check Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonSessionCheckPost: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/crm/person/session_check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Settings Route
     * @param {CrmPersonSettingsRequest} crmPersonSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonSettingsPost: async (
      crmPersonSettingsRequest: CrmPersonSettingsRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonSettingsRequest' is not null or undefined
      assertParamExists(
        "crmPersonSettingsPost",
        "crmPersonSettingsRequest",
        crmPersonSettingsRequest
      );
      const localVarPath = `/crm/person/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "token", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        crmPersonSettingsRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Examples:      /public/dynamic/payoff.jpg?message=Le%20projet%20est%20presque%20termin%C3%A9     /public/dynamic/banner-en-progression-preparation.jpg?message=The%20status|of%20your%20fiber|optic%20connection
     * @summary Cdn Data Dynamic Route
     * @param {string} which
     * @param {string} message
     * @param {number} [refresh]
     * @param {string} [position]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicDynamicWhichJpgGet: async (
      which: string,
      message: string,
      refresh?: number,
      position?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'which' is not null or undefined
      assertParamExists("publicDynamicWhichJpgGet", "which", which);
      // verify required parameter 'message' is not null or undefined
      assertParamExists("publicDynamicWhichJpgGet", "message", message);
      const localVarPath = `/public/dynamic/{which}.jpg`.replace(
        `{${"which"}}`,
        encodeURIComponent(String(which))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (message !== undefined) {
        localVarQueryParameter["message"] = message;
      }

      if (refresh !== undefined) {
        localVarQueryParameter["refresh"] = refresh;
      }

      if (position !== undefined) {
        localVarQueryParameter["position"] = position;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cdn Data Public Route
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicImagesFilenameGet: async (
      filename: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'filename' is not null or undefined
      assertParamExists("publicImagesFilenameGet", "filename", filename);
      const localVarPath = `/public/images/{filename}`.replace(
        `{${"filename"}}`,
        encodeURIComponent(String(filename))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cdn Data Message Route
     * @param {string} stem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicMessagesStemHtmlGet: async (
      stem: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'stem' is not null or undefined
      assertParamExists("publicMessagesStemHtmlGet", "stem", stem);
      const localVarPath = `/public/messages/{stem}.html`.replace(
        `{${"stem"}}`,
        encodeURIComponent(String(stem))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resource Image Resize Route
     * @param {string} o
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resourceImageResizeOFilenameGet: async (
      o: string,
      filename: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'o' is not null or undefined
      assertParamExists("resourceImageResizeOFilenameGet", "o", o);
      // verify required parameter 'filename' is not null or undefined
      assertParamExists(
        "resourceImageResizeOFilenameGet",
        "filename",
        filename
      );
      const localVarPath = `/resource_image/resize/{o}/{filename}`
        .replace(`{${"o"}}`, encodeURIComponent(String(o)))
        .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resource Image Resize Route
     * @param {string} o
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resourceImageResizePost: async (
      o: string,
      filename: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'o' is not null or undefined
      assertParamExists("resourceImageResizePost", "o", o);
      // verify required parameter 'filename' is not null or undefined
      assertParamExists("resourceImageResizePost", "filename", filename);
      const localVarPath = `/resource/image/resize`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (o !== undefined) {
        localVarQueryParameter["o"] = o;
      }

      if (filename !== undefined) {
        localVarQueryParameter["filename"] = filename;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary App I11N Many Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appI11nManyPost(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AppI11nManyResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appI11nManyPost(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary App I11N Save Route
     * @param {AppI11nSaveRequest} appI11nSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appI11nSavePost(
      appI11nSaveRequest: AppI11nSaveRequest,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appI11nSavePost(
        appI11nSaveRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Cdn Mailer Build Route
     * @param {CdnMailerRequestDto} cdnMailerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdnMailerBuildPost(
      cdnMailerRequestDto: CdnMailerRequestDto,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CdnMailerBuildResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cdnMailerBuildPost(
          cdnMailerRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Cdn Mailer Html Route
     * @param {CdnMailerRequestDto} cdnMailerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdnMailerHtmlPost(
      cdnMailerRequestDto: CdnMailerRequestDto,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cdnMailerHtmlPost(
          cdnMailerRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Cdn Mailer Many Route
     * @param {CdnMailerManyRequest} cdnMailerManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdnMailerManyPost(
      cdnMailerManyRequest: CdnMailerManyRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CdnMailerManyResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cdnMailerManyPost(
          cdnMailerManyRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Cdn Mailer One Route
     * @param {CdnMailerOneRequest} cdnMailerOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdnMailerOnePost(
      cdnMailerOneRequest: CdnMailerOneRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CdnMailerOneResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cdnMailerOnePost(
          cdnMailerOneRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *  Send an message to one or more recipients. Excite will automatically setup the message based on the passed request. A response containing a set of identifiers is returned, which can be used to check the status of the message.  **Warnings:**  - A minimum of one address is required, any of the options (to, cc, or bcc). - An invalid e-mailaddress will fail the request. - All the provided e-mailaddresses _must_ be unique (to, cc, or bcc). - The message must never weigh more than 15Mb (attachments).
     * @summary Cdn Mailer Send Route
     * @param {CdnMailerRequestDto} cdnMailerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdnMailerSendPost(
      cdnMailerRequestDto: CdnMailerRequestDto,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CdnMailerSendResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cdnMailerSendPost(
          cdnMailerRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Crm Person Authenticate Route
     * @param {CrmPersonAuthenticateRequest} crmPersonAuthenticateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonAuthenticatePost(
      crmPersonAuthenticateRequest: CrmPersonAuthenticateRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CrmPersonAuthenticateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.crmPersonAuthenticatePost(
          crmPersonAuthenticateRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Crm Person Many Route
     * @param {CrmPersonManyRequest} crmPersonManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonManyPost(
      crmPersonManyRequest: CrmPersonManyRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CrmPersonManyResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.crmPersonManyPost(
          crmPersonManyRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Crm Person One Route
     * @param {CrmPersonOneRequest} crmPersonOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonOnePost(
      crmPersonOneRequest: CrmPersonOneRequest,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmPersonDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.crmPersonOnePost(
          crmPersonOneRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Crm Person Reset Password Code Route
     * @param {CrmPersonResetPasswordCodeDto} crmPersonResetPasswordCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonResetPasswordCodePost(
      crmPersonResetPasswordCodeDto: CrmPersonResetPasswordCodeDto,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CrmPersonResetPasswordCodeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.crmPersonResetPasswordCodePost(
          crmPersonResetPasswordCodeDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Crm Person Reset Password Route
     * @param {CrmPersonResetPasswordDto} crmPersonResetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonResetPasswordPost(
      crmPersonResetPasswordDto: CrmPersonResetPasswordDto,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CrmPersonAuthenticateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.crmPersonResetPasswordPost(
          crmPersonResetPasswordDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Crm Person Save Route
     * @param {CrmPersonSaveRequest} crmPersonSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonSavePost(
      crmPersonSaveRequest: CrmPersonSaveRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CrmPersonSaveResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.crmPersonSavePost(
          crmPersonSaveRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Crm Person Session Check Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonSessionCheckPost(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CrmPersonAuthenticateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.crmPersonSessionCheckPost(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Crm Person Settings Route
     * @param {CrmPersonSettingsRequest} crmPersonSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonSettingsPost(
      crmPersonSettingsRequest: CrmPersonSettingsRequest,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.crmPersonSettingsPost(
          crmPersonSettingsRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Examples:      /public/dynamic/payoff.jpg?message=Le%20projet%20est%20presque%20termin%C3%A9     /public/dynamic/banner-en-progression-preparation.jpg?message=The%20status|of%20your%20fiber|optic%20connection
     * @summary Cdn Data Dynamic Route
     * @param {string} which
     * @param {string} message
     * @param {number} [refresh]
     * @param {string} [position]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicDynamicWhichJpgGet(
      which: string,
      message: string,
      refresh?: number,
      position?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.publicDynamicWhichJpgGet(
          which,
          message,
          refresh,
          position,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Cdn Data Public Route
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicImagesFilenameGet(
      filename: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.publicImagesFilenameGet(
          filename,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Cdn Data Message Route
     * @param {string} stem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicMessagesStemHtmlGet(
      stem: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.publicMessagesStemHtmlGet(
          stem,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Resource Image Resize Route
     * @param {string} o
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resourceImageResizeOFilenameGet(
      o: string,
      filename: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resourceImageResizeOFilenameGet(
          o,
          filename,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Resource Image Resize Route
     * @param {string} o
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resourceImageResizePost(
      o: string,
      filename: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resourceImageResizePost(
          o,
          filename,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary App I11N Many Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appI11nManyPost(options?: any): AxiosPromise<AppI11nManyResponse> {
      return localVarFp
        .appI11nManyPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary App I11N Save Route
     * @param {AppI11nSaveRequest} appI11nSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appI11nSavePost(
      appI11nSaveRequest: AppI11nSaveRequest,
      options?: any
    ): AxiosPromise<DetailResponse> {
      return localVarFp
        .appI11nSavePost(appI11nSaveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cdn Mailer Build Route
     * @param {CdnMailerRequestDto} cdnMailerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdnMailerBuildPost(
      cdnMailerRequestDto: CdnMailerRequestDto,
      options?: any
    ): AxiosPromise<CdnMailerBuildResponse> {
      return localVarFp
        .cdnMailerBuildPost(cdnMailerRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cdn Mailer Html Route
     * @param {CdnMailerRequestDto} cdnMailerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdnMailerHtmlPost(
      cdnMailerRequestDto: CdnMailerRequestDto,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .cdnMailerHtmlPost(cdnMailerRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cdn Mailer Many Route
     * @param {CdnMailerManyRequest} cdnMailerManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdnMailerManyPost(
      cdnMailerManyRequest: CdnMailerManyRequest,
      options?: any
    ): AxiosPromise<CdnMailerManyResponse> {
      return localVarFp
        .cdnMailerManyPost(cdnMailerManyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cdn Mailer One Route
     * @param {CdnMailerOneRequest} cdnMailerOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdnMailerOnePost(
      cdnMailerOneRequest: CdnMailerOneRequest,
      options?: any
    ): AxiosPromise<CdnMailerOneResponse> {
      return localVarFp
        .cdnMailerOnePost(cdnMailerOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *  Send an message to one or more recipients. Excite will automatically setup the message based on the passed request. A response containing a set of identifiers is returned, which can be used to check the status of the message.  **Warnings:**  - A minimum of one address is required, any of the options (to, cc, or bcc). - An invalid e-mailaddress will fail the request. - All the provided e-mailaddresses _must_ be unique (to, cc, or bcc). - The message must never weigh more than 15Mb (attachments).
     * @summary Cdn Mailer Send Route
     * @param {CdnMailerRequestDto} cdnMailerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdnMailerSendPost(
      cdnMailerRequestDto: CdnMailerRequestDto,
      options?: any
    ): AxiosPromise<CdnMailerSendResponse> {
      return localVarFp
        .cdnMailerSendPost(cdnMailerRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Authenticate Route
     * @param {CrmPersonAuthenticateRequest} crmPersonAuthenticateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonAuthenticatePost(
      crmPersonAuthenticateRequest: CrmPersonAuthenticateRequest,
      options?: any
    ): AxiosPromise<CrmPersonAuthenticateResponse> {
      return localVarFp
        .crmPersonAuthenticatePost(crmPersonAuthenticateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Many Route
     * @param {CrmPersonManyRequest} crmPersonManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonManyPost(
      crmPersonManyRequest: CrmPersonManyRequest,
      options?: any
    ): AxiosPromise<CrmPersonManyResponse> {
      return localVarFp
        .crmPersonManyPost(crmPersonManyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person One Route
     * @param {CrmPersonOneRequest} crmPersonOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonOnePost(
      crmPersonOneRequest: CrmPersonOneRequest,
      options?: any
    ): AxiosPromise<CrmPersonDto> {
      return localVarFp
        .crmPersonOnePost(crmPersonOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Reset Password Code Route
     * @param {CrmPersonResetPasswordCodeDto} crmPersonResetPasswordCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonResetPasswordCodePost(
      crmPersonResetPasswordCodeDto: CrmPersonResetPasswordCodeDto,
      options?: any
    ): AxiosPromise<CrmPersonResetPasswordCodeResponse> {
      return localVarFp
        .crmPersonResetPasswordCodePost(crmPersonResetPasswordCodeDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Reset Password Route
     * @param {CrmPersonResetPasswordDto} crmPersonResetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonResetPasswordPost(
      crmPersonResetPasswordDto: CrmPersonResetPasswordDto,
      options?: any
    ): AxiosPromise<CrmPersonAuthenticateResponse> {
      return localVarFp
        .crmPersonResetPasswordPost(crmPersonResetPasswordDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Save Route
     * @param {CrmPersonSaveRequest} crmPersonSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonSavePost(
      crmPersonSaveRequest: CrmPersonSaveRequest,
      options?: any
    ): AxiosPromise<CrmPersonSaveResponse> {
      return localVarFp
        .crmPersonSavePost(crmPersonSaveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Session Check Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonSessionCheckPost(
      options?: any
    ): AxiosPromise<CrmPersonAuthenticateResponse> {
      return localVarFp
        .crmPersonSessionCheckPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Settings Route
     * @param {CrmPersonSettingsRequest} crmPersonSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonSettingsPost(
      crmPersonSettingsRequest: CrmPersonSettingsRequest,
      options?: any
    ): AxiosPromise<DetailResponse> {
      return localVarFp
        .crmPersonSettingsPost(crmPersonSettingsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Examples:      /public/dynamic/payoff.jpg?message=Le%20projet%20est%20presque%20termin%C3%A9     /public/dynamic/banner-en-progression-preparation.jpg?message=The%20status|of%20your%20fiber|optic%20connection
     * @summary Cdn Data Dynamic Route
     * @param {string} which
     * @param {string} message
     * @param {number} [refresh]
     * @param {string} [position]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicDynamicWhichJpgGet(
      which: string,
      message: string,
      refresh?: number,
      position?: string,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .publicDynamicWhichJpgGet(which, message, refresh, position, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cdn Data Public Route
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicImagesFilenameGet(
      filename: string,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .publicImagesFilenameGet(filename, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cdn Data Message Route
     * @param {string} stem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicMessagesStemHtmlGet(stem: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .publicMessagesStemHtmlGet(stem, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Resource Image Resize Route
     * @param {string} o
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resourceImageResizeOFilenameGet(
      o: string,
      filename: string,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .resourceImageResizeOFilenameGet(o, filename, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Resource Image Resize Route
     * @param {string} o
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resourceImageResizePost(
      o: string,
      filename: string,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .resourceImageResizePost(o, filename, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary App I11N Many Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appI11nManyPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .appI11nManyPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary App I11N Save Route
   * @param {AppI11nSaveRequest} appI11nSaveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appI11nSavePost(
    appI11nSaveRequest: AppI11nSaveRequest,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .appI11nSavePost(appI11nSaveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cdn Mailer Build Route
   * @param {CdnMailerRequestDto} cdnMailerRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdnMailerBuildPost(
    cdnMailerRequestDto: CdnMailerRequestDto,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .cdnMailerBuildPost(cdnMailerRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cdn Mailer Html Route
   * @param {CdnMailerRequestDto} cdnMailerRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdnMailerHtmlPost(
    cdnMailerRequestDto: CdnMailerRequestDto,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .cdnMailerHtmlPost(cdnMailerRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cdn Mailer Many Route
   * @param {CdnMailerManyRequest} cdnMailerManyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdnMailerManyPost(
    cdnMailerManyRequest: CdnMailerManyRequest,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .cdnMailerManyPost(cdnMailerManyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cdn Mailer One Route
   * @param {CdnMailerOneRequest} cdnMailerOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdnMailerOnePost(
    cdnMailerOneRequest: CdnMailerOneRequest,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .cdnMailerOnePost(cdnMailerOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *  Send an message to one or more recipients. Excite will automatically setup the message based on the passed request. A response containing a set of identifiers is returned, which can be used to check the status of the message.  **Warnings:**  - A minimum of one address is required, any of the options (to, cc, or bcc). - An invalid e-mailaddress will fail the request. - All the provided e-mailaddresses _must_ be unique (to, cc, or bcc). - The message must never weigh more than 15Mb (attachments).
   * @summary Cdn Mailer Send Route
   * @param {CdnMailerRequestDto} cdnMailerRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdnMailerSendPost(
    cdnMailerRequestDto: CdnMailerRequestDto,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .cdnMailerSendPost(cdnMailerRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Authenticate Route
   * @param {CrmPersonAuthenticateRequest} crmPersonAuthenticateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonAuthenticatePost(
    crmPersonAuthenticateRequest: CrmPersonAuthenticateRequest,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .crmPersonAuthenticatePost(crmPersonAuthenticateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Many Route
   * @param {CrmPersonManyRequest} crmPersonManyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonManyPost(
    crmPersonManyRequest: CrmPersonManyRequest,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .crmPersonManyPost(crmPersonManyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person One Route
   * @param {CrmPersonOneRequest} crmPersonOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonOnePost(
    crmPersonOneRequest: CrmPersonOneRequest,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .crmPersonOnePost(crmPersonOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Reset Password Code Route
   * @param {CrmPersonResetPasswordCodeDto} crmPersonResetPasswordCodeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonResetPasswordCodePost(
    crmPersonResetPasswordCodeDto: CrmPersonResetPasswordCodeDto,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .crmPersonResetPasswordCodePost(crmPersonResetPasswordCodeDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Reset Password Route
   * @param {CrmPersonResetPasswordDto} crmPersonResetPasswordDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonResetPasswordPost(
    crmPersonResetPasswordDto: CrmPersonResetPasswordDto,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .crmPersonResetPasswordPost(crmPersonResetPasswordDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Save Route
   * @param {CrmPersonSaveRequest} crmPersonSaveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonSavePost(
    crmPersonSaveRequest: CrmPersonSaveRequest,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .crmPersonSavePost(crmPersonSaveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Session Check Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonSessionCheckPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .crmPersonSessionCheckPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Settings Route
   * @param {CrmPersonSettingsRequest} crmPersonSettingsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonSettingsPost(
    crmPersonSettingsRequest: CrmPersonSettingsRequest,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .crmPersonSettingsPost(crmPersonSettingsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Examples:      /public/dynamic/payoff.jpg?message=Le%20projet%20est%20presque%20termin%C3%A9     /public/dynamic/banner-en-progression-preparation.jpg?message=The%20status|of%20your%20fiber|optic%20connection
   * @summary Cdn Data Dynamic Route
   * @param {string} which
   * @param {string} message
   * @param {number} [refresh]
   * @param {string} [position]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicDynamicWhichJpgGet(
    which: string,
    message: string,
    refresh?: number,
    position?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .publicDynamicWhichJpgGet(which, message, refresh, position, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cdn Data Public Route
   * @param {string} filename
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicImagesFilenameGet(filename: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .publicImagesFilenameGet(filename, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cdn Data Message Route
   * @param {string} stem
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicMessagesStemHtmlGet(stem: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .publicMessagesStemHtmlGet(stem, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Resource Image Resize Route
   * @param {string} o
   * @param {string} filename
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public resourceImageResizeOFilenameGet(
    o: string,
    filename: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .resourceImageResizeOFilenameGet(o, filename, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Resource Image Resize Route
   * @param {string} o
   * @param {string} filename
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public resourceImageResizePost(o: string, filename: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .resourceImageResizePost(o, filename, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

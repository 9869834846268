import React from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { MailerFormData } from "./MailerPage";
import { FormikProps } from "formik";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import MailerPageProgressNniForm from "./MailerPageProgressNniForm";
import MailerPageProgressPopForm from "./MailerPageProgressPopForm";
import MailerPageProgressTurnOutForm from "./MailerPageProgressTurnOutForm";
import MailerPageProgressExecutiveActivitiesForm from "./MailerPageProgressExecutiveActivitiesForm";

const MailerPageProgressModuleForm = (props: FormikProps<MailerFormData>) => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = props;
  const {
    has_nni,
    has_pop,
    has_loa,
    has_executive_activities,
    has_turnaround_permit,
    last_cdd_date_customer,
    remark,
  } = values?.progress || {};

  const handleDateChange = (name: string) => {
    return (date: any) => {
      // the name refers to the value and error
      // of this field. In fact, this would be the [name=""].
      if (isValid(date)) {
        // When the data picker returns a valid date
        // , we store the value in Formik.
        setFieldValue(name, format(date, "yyyy-MM-dd"));
      } else {
        //  datepicker return an invalid date,
        // then we store null so the API doesn't complain.
        setFieldValue(name, null);
      }
    };
  };

  return (
    <Box p={2}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="progress.has_nni"
                  value={has_nni}
                  onChange={handleChange}
                  checked={has_nni ? true : false}
                  color="primary"
                />
              }
              label="NNI"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="progress.has_pop"
                  value={has_pop}
                  onChange={handleChange}
                  checked={has_pop ? true : false}
                  color="primary"
                />
              }
              label="POP"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="progress.has_loa"
                  value={has_loa}
                  onChange={handleChange}
                  checked={has_loa ? true : false}
                  color="primary"
                />
              }
              label="LOA"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="progress.has_turnaround_permit"
                  value={has_turnaround_permit}
                  onChange={handleChange}
                  checked={has_turnaround_permit ? true : false}
                  color="primary"
                />
              }
              label="Turnaround Permit"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="progress.has_executive_activities"
                  value={has_executive_activities}
                  onChange={handleChange}
                  checked={has_executive_activities ? true : false}
                  color="primary"
                />
              }
              label="Executive Activities"
            />
          </Grid>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd-MM-yyyy"
                margin="normal"
                label="Last CDD Date Customer"
                value={last_cdd_date_customer || null}
                error={!!errors.progress?.last_cdd_date_customer}
                onChange={handleDateChange("progress.last_cdd_date_customer")}
              />
            </Grid>
          </MuiPickersUtilsProvider>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Remark"
              name="progress.remark"
              value={remark || ""}
              onChange={handleChange}
              error={!!errors.progress?.remark}
              helperText={
                touched.progress?.remark ? !!errors.progress?.remark : ""
              }
            />
          </Grid>
        </Grid>
      </form>

      <Box p={1}>
        {values.progress?.has_nni === true && (
          <MailerPageProgressNniForm nni={values.progress?.nni} {...props} />
        )}
        {values.progress?.has_pop === true && (
          <MailerPageProgressPopForm {...props} pop={values.progress?.nni} />
        )}
        {values.progress?.has_turnaround_permit === true && (
          <MailerPageProgressTurnOutForm
            {...props}
            turnaround_permit={values.progress?.turnaround_permit}
          />
        )}
        {values.progress?.has_executive_activities === true && (
          <MailerPageProgressExecutiveActivitiesForm
            {...props}
            executive_activities={values.progress?.executive_activities}
          />
        )}
      </Box>
    </Box>
  );
};

export default MailerPageProgressModuleForm;

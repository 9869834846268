import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import sleep from "../../Ext/sleep";
import get_api from "../../Ext/get_api";
import { AxiosError } from "axios";

const useStyles = makeStyles((theme) => ({
  Dialog_SubmitBtnProgress: {
    position: "absolute",
    bottom: "0px",
    height: "4px",
    left: "0px",
    right: "0px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    backgroundColor: "transparent",

    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  id: number;
  email: string;
};

type FormData = {
  oldPassword: string;
  newPassword: string;
};
const validationSchema = Yup.object({
  oldPassword: Yup.string().min(8).required("user has no password"),
  newPassword: Yup.string().min(
    8,
    "password must be at least 8 characters long"
  ),
});
const ManagementUserPageChangePasswordDialog: React.FC<Props> = (props) => {
  const classes = useStyles();
  const values = { oldPassword: "", newPassword: "" };
  const onSubmit = async (data: FormData, actions: FormikHelpers<FormData>) => {
    const api = get_api();
    try {
      await sleep(1500);
      await api.crmPersonAuthenticatePost({
        email: props.email,
        password: data.oldPassword,
      });
      await api.crmPersonSettingsPost({
        password: data.newPassword,
        id: props.id,
      });

      // waiting for API
      actions.setSubmitting(false);
    } catch (e) {
      if (e.response) {
        const exc: AxiosError = e;
        const res = exc.response;
        console.log(res?.status, res?.data, res?.headers);
        if (res?.data.detail === "ACCOUNT_NO_PASSWORD") {
          actions.setErrors({
            oldPassword: "User has no password",
          });
        }
      } else {
        console.error(e);
        actions.setErrors({
          oldPassword: "Something went wrong",
        });
      }
    }
  };
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <Formik<FormData>
        validationSchema={validationSchema}
        initialValues={values}
        onSubmit={onSubmit}
      >
        {(fp: FormikProps<FormData>) => {
          const {
            values: { oldPassword, newPassword },
            errors,
            touched,
            handleSubmit,
            handleChange,
            isSubmitting,
          } = fp;

          return (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              style={{ height: "100%" }}
            >
              <Box padding={4}>
                <DialogTitle disableTypography>
                  <Typography variant="h4">Change password</Typography>
                  <Typography variant="subtitle2">
                    Please enter new password here.
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        type="password"
                        name="oldPassword"
                        value={oldPassword}
                        helperText={
                          touched.oldPassword && !!errors.oldPassword
                            ? errors.oldPassword
                            : ""
                        }
                        error={!!errors.oldPassword}
                        onChange={handleChange}
                        fullWidth
                        label="old password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        type="password"
                        name="newPassword"
                        value={newPassword}
                        helperText={touched ? !!errors.newPassword : ""}
                        error={!!errors.newPassword}
                        onChange={handleChange}
                        fullWidth
                        label="new password"
                      />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button variant="outlined" onClick={props.onClose}>
                      cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="outlined"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && (
                        <LinearProgress
                          className={classes.Dialog_SubmitBtnProgress}
                        />
                      )}
                      save
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ManagementUserPageChangePasswordDialog;

import React from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import sleep from "../../Ext/sleep";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import get_api from "../../Ext/get_api";

const useStyles = makeStyles((theme) => ({
  Table__Rows: {
    borderRight: "1px solid #C0C0C0",
    padding: "0px 16px",
  },
  Drawer_Container: {
    "& .MuiPaper-root": {
      width: "30vw",
    },
  },
  Drawer_SubmitBtnProgress: {
    position: "absolute",
    bottom: "0px",
    height: "4px",
    left: "0px",
    right: "0px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    backgroundColor: "transparent",

    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.primary.light,
      //   backgroundColor: "red",
    },
  },
}));

type ComponentProps = {
  language: string;
  value: string;
  open: boolean;
  handleClose: () => void;
  id: number;
  load: () => void;
};

type FormData = {
  value: string;
  id: number;
  language: string;
};

const validationSchema = Yup.object({
  value: Yup.string().min(1),
});
const ManagementLanguagePageEditDrawer = (props: ComponentProps) => {
  const classes = useStyles();

  const values: FormData = {
    value: props.value,
    id: props.id,
    language: props.language,
  };
  const onSubmit = async (data: FormData, actions: FormikHelpers<FormData>) => {
    await sleep(350);
    const api = get_api();
    try {
      await api.appI11nSavePost({
        language_id: parseInt(data.language),
        localization_id: data.id,
        value: data.value,
      });

      actions.setSubmitting(false);
      props.load();
      props.handleClose();
    } catch (e) {
      console.error(e);
      actions.setErrors({
        value: "not found",
      });
    }
  };

  return (
    <Drawer
      className={classes.Drawer_Container}
      anchor="right"
      open={props.open}
      onClose={props.handleClose}
    >
      <Formik<FormData>
        initialValues={values}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(fp: FormikProps<FormData>) => {
          const {
            values: { value },
            errors,
            touched,
            handleSubmit,
            handleChange,
            isSubmitting,
          } = fp;

          return (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              style={{ height: "100%" }}
            >
              <Box
                p={2}
                paddingTop={4}
                display="flex"
                flexDirection="column"
                height="100%"
              >
                <DialogTitle disableTypography>
                  <Typography variant="h4">Edit translation</Typography>
                </DialogTitle>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        name="value"
                        fullWidth
                        multiline
                        helperText={
                          touched.value && !!errors.value ? errors.value : ""
                        }
                        error={!!errors.value}
                        value={value}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={props.handleClose} variant="outlined">
                    cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && (
                      <LinearProgress
                        className={classes.Drawer_SubmitBtnProgress}
                      />
                    )}
                    submit
                  </Button>
                </DialogActions>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Drawer>
  );
};

export default ManagementLanguagePageEditDrawer;

import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
} from "@material-ui/core";
import { CdnMailerRequestProgressExecutiveActivitiesDto } from "../../api";
import { FormikProps } from "formik";
import { MailerFormData } from "./MailerPage";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles((theme) => ({
  Module__Accordion: {
    "&:not(:first-child)": {
      marginTop: "10px",
    },
  },
}));

type Props = {
  executive_activities?: CdnMailerRequestProgressExecutiveActivitiesDto;
};
const MailerPageProgressExecutiveActivitiesForm = (
  props: FormikProps<MailerFormData> & Props
) => {
  const classes = useStyles();
  const { values, errors, touched, handleChange } = props;
  const formValues = values.progress?.executive_activities;
  const formPrefix = `progress.executive_activities`;
  const formErrors: any = errors.progress?.executive_activities;
  const formTouched: any = touched.progress?.executive_activities;
  return (
    <Box>
      <Accordion
        className={classes.Module__Accordion}
        style={{ marginBottom: "30px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Box paddingLeft={2}>
            <h2>Executive Activities</h2>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Weeks"
                name={`${formPrefix}.weeks`}
                value={formValues?.weeks}
                error={!!formErrors?.weeks}
                helperText={formTouched?.weeks ? !!formErrors?.weeks : ""}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    name={`${formPrefix}.has_controlled_drilling`}
                    value={formValues?.has_controlled_drilling}
                    onChange={handleChange}
                    checked={!!formValues?.has_controlled_drilling}
                    color="primary"
                  />
                }
                label="Controlled Drilling"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    name={`${formPrefix}.has_dependency_project`}
                    value={formValues?.has_dependency_project}
                    onChange={handleChange}
                    checked={!!formValues?.has_dependency_project}
                    color="primary"
                  />
                }
                label="Dependency Project"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Project Number"
                name={`${formPrefix}.project_number`}
                value={formValues?.project_number}
                error={!!formErrors?.project_number}
                helperText={
                  formTouched?.project_number
                    ? !!formErrors?.project_number
                    : ""
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    name={`${formPrefix}.has_top_10_weld`}
                    value={formValues?.has_top_10_weld}
                    onChange={handleChange}
                    checked={!!formValues?.has_top_10_weld}
                    color="primary"
                  />
                }
                label="Top Ten 10  Weld"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    name={`${formPrefix}.has_server_room_ready`}
                    value={formValues?.has_server_room_ready}
                    onChange={handleChange}
                    checked={!!formValues?.has_server_room_ready}
                    color="primary"
                  />
                }
                label="Server Room Ready"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default MailerPageProgressExecutiveActivitiesForm;

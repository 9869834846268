import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
} from "@material-ui/core";
import { FormikProps } from "formik";
import { MailerFormData } from "./MailerPage";
import { CdnMailerRequestProgressTurnaroundPermitDto } from "../../api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles((theme) => ({
  Module__Accordion: {
    "&:not(:first-child)": {
      marginTop: "10px",
    },
  },
}));

type Props = {
  turnaround_permit?: CdnMailerRequestProgressTurnaroundPermitDto;
};
const MailerPageProgressTurnOutForm = (
  props: FormikProps<MailerFormData> & Props
) => {
  const classes = useStyles();
  const { values, errors, touched, handleChange } = props;
  const formValues = values.progress?.turnaround_permit;
  const formPrefix = `progress.turnaround_permit`;
  const formErrors: any = errors.progress?.turnaround_permit;
  const formTouched: any = touched.progress?.turnaround_permit;

  return (
    <Box>
      <Accordion
        className={classes.Module__Accordion}
        style={{ marginBottom: "30px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Box paddingLeft={2}>
            <h2>Turnout Permit</h2>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Weeks"
                name={`${formPrefix}.weeks`}
                value={formValues?.weeks}
                error={!!formErrors?.weeks}
                helperText={formTouched?.weeks ? !!formErrors?.weeks : ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    name={`${formPrefix}.has_soil_survey`}
                    value={formValues?.has_soil_survey}
                    onChange={handleChange}
                    checked={formValues?.has_soil_survey ? true : false}
                    color="primary"
                  />
                }
                label="Soil Survey"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default MailerPageProgressTurnOutForm;

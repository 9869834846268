import React from "react";
import LoginForgotPasswordPageForm from "./LoginForgotPasswordPageForm";
import {
  Card,
  CardContent,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import sleep from "../../Ext/sleep";
import get_api from "../../Ext/get_api";
import { AxiosError } from "axios";

const useStyles = makeStyles((theme) => ({
  Login__Card: {
    marginTop: "80px",
    height: "500px",
    backgroundColor: theme.palette.primary.dark,
  },
  Login__Typography: {
    color: "#fff",
    marginLeft: "20px",
  },
  Login__SubTypography: {
    color: "#C8C8C8",
    margin: "0px 20px",
  },
  Logo: {
    width: "100%",
    height: 100,
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    marginTop: "30px",
  },
}));

export type ForgotPasswordPageFormData = {
  email: string;
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const LoginForgotPasswordPage = () => {
  const classes = useStyles();
  let history = useHistory();

  const values: ForgotPasswordPageFormData = { email: "" };

  const Submit = async (
    data: ForgotPasswordPageFormData,
    actions: FormikHelpers<ForgotPasswordPageFormData>
  ) => {
    const api = get_api();
    try {
      await sleep(350);
      const cpa_res = await api.crmPersonResetPasswordCodePost({
        email: data.email,
      });

      console.log("Use the following code:", cpa_res.data.code_token);
      // After talking to the API, mark the form done.
      actions.setSubmitting(false);
      history.push({
        pathname: "/unauthorized/recover-password",
        state: { code_id: cpa_res.data.code_id },
      });
    } catch (e) {
      if (e.response) {
        const exc: AxiosError = e;
        const res = exc.response;
        console.log(res?.status, res?.data, res?.headers);
        if (res?.data.detail[0].type === "value_error.email") {
          actions.setErrors({
            email: "Please enter a valid email",
          });
        }
      } else {
        actions.setErrors({ email: "something went wrong" });
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Card className={classes.Login__Card}>
        <CardContent>
          <Typography
            className={classes.Login__Typography}
            variant="h5"
            component="h2"
          >
            Forgot your password?
          </Typography>
          <Typography className={classes.Login__SubTypography} gutterBottom>
            Please enter your email address to receive instructions one how to
            reset your password.
          </Typography>
        </CardContent>
        <CardContent>
          <Formik<ForgotPasswordPageFormData>
            initialValues={values}
            validationSchema={validationSchema}
            render={(props) => <LoginForgotPasswordPageForm {...props} />}
            onSubmit={Submit}
          ></Formik>
        </CardContent>
      </Card>
    </Container>
  );
};

export default LoginForgotPasswordPage;

import React, { useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { FormikProps } from "formik";
import { MailerFormData } from "./MailerPage";

const MailerPageGeneralForm = (props: FormikProps<MailerFormData>) => {
  const { values, errors, touched, handleSubmit, handleChange } = props;
  const {
    deliver,
    debug_mode,
    reply_address,
    from_address,
    expectation_video,
    foreign_id,
    to_address_set,
    cc_address_set,
    bcc_address_set,
    attachment_set,
  } = values;
  const [toAddressAmount, setToAdressAmount] = useState(0);
  const [ccAddressAmount, setCcAddressAmount] = useState(0);
  const [bccAddressSetAmount, setBccAdressSetAmount] = useState(0);
  const [attachmentSetAmount, setAttachmentAmount] = useState(0);

  return (
    <Box p={2} paddingTop={0}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Foreign Id"
              name="foreign_id"
              value={foreign_id || ""}
              onChange={handleChange}
              helperText={touched.foreign_id ? !!errors.foreign_id : ""}
              error={!!errors.foreign_id}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="From Name"
              name="from_address.name"
              value={from_address?.name || ""}
              onChange={handleChange}
              helperText={
                touched.from_address?.name ? !!errors.from_address?.name : ""
              }
              error={!!errors.from_address?.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="From Email"
              name="from_address.email"
              value={from_address?.email || ""}
              onChange={handleChange}
              helperText={
                touched.from_address?.email
                  ? !!errors.from_address?.email
                  : errors.from_address?.email
              }
              error={!!errors.from_address?.email}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Reply Name"
              name="reply_address.name"
              value={reply_address?.name || ""}
              onChange={handleChange}
              helperText={
                touched.reply_address?.name ? !!errors.reply_address?.name : ""
              }
              error={!!errors.reply_address?.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Reply Email"
              name="reply_address.email"
              value={reply_address?.email || ""}
              onChange={handleChange}
              helperText={
                touched.reply_address?.email
                  ? !!errors.reply_address?.email
                  : errors.reply_address?.email
              }
              error={!!errors.reply_address?.email}
            />
          </Grid>
          {/* check to see if works */}
          <Grid item xs={12}>
            <TextField
              name="toAddressAmount"
              value={toAddressAmount}
              onChange={(evt) =>
                setToAdressAmount(parseInt(evt.target.value, 10) || 0)
              }
              label="To: Address Amount"
              fullWidth
            />
          </Grid>
          {[...Array(toAddressAmount)].map((x, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <TextField
                  name={`to_address_set[${index}].name`}
                  label="Name"
                  value={to_address_set[index]?.name || ""}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name={`to_address_set[${index}].email`}
                  label="Email"
                  value={to_address_set[index]?.email || ""}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <TextField
              name="ccAddressAmount"
              value={ccAddressAmount}
              onChange={(evt) =>
                setCcAddressAmount(parseInt(evt.target.value, 10) || 0)
              }
              label="CC: Address Amount"
              fullWidth
            />
          </Grid>
          {[...Array(ccAddressAmount)].map((x, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <TextField
                  name={`cc_address_set[${index}].name`}
                  label="Name"
                  value={cc_address_set[index]?.name || ""}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name={`cc_address_set[${index}].email`}
                  label="Email "
                  value={cc_address_set[index]?.email || ""}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <TextField
              name="bccAddressSetAmount"
              value={bccAddressSetAmount}
              onChange={(evt) =>
                setBccAdressSetAmount(parseInt(evt.target.value, 10) || 0)
              }
              label="BCC: Address Amount"
              fullWidth
            />
          </Grid>
          {[...Array(bccAddressSetAmount)].map((x, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <TextField
                  name={`bcc_address_set[${index}].name`}
                  label="Name"
                  value={bcc_address_set[index]?.name || ""}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name={`bcc_address_set[${index}].email`}
                  label="Email"
                  value={bcc_address_set[index]?.email || ""}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <TextField
              name="attachmentSetAmount"
              value={attachmentSetAmount}
              onChange={(evt) =>
                setAttachmentAmount(parseInt(evt.target.value, 10) || 0)
              }
              label="Attachment Amount"
              fullWidth
            />
          </Grid>

          {[...Array(attachmentSetAmount)].map((x, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <TextField
                  name={`attachment_set[${index}].name`}
                  label="Name"
                  value={attachment_set[index]?.name || ""}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name={`attachment_set[${index}].content`}
                  label="Content"
                  value={attachment_set[index]?.content || ""}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="expectation_video"
                  value={expectation_video}
                  onChange={handleChange}
                  checked={expectation_video === false ? false : true}
                  color="primary"
                />
              }
              label="Expectation Video"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="debug_mode"
                  value={debug_mode}
                  onChange={handleChange}
                  checked={debug_mode === false ? false : true}
                  color="primary"
                />
              }
              label="Debug"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="deliver"
                  value={deliver}
                  onChange={handleChange}
                  checked={deliver === true ? true : false}
                  color="primary"
                />
              }
              label="Deliver"
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default MailerPageGeneralForm;

import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    textAlign: "center",
  },
}));

const HomeViewPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h1>Welcome!</h1>
      <Button component={Link} to="/unauthorized/login">
        Click here to log in
      </Button>
    </div>
  );
};
export default HomeViewPage;

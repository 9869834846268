import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  TableCell,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import get_api from "../../Ext/get_api";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles((theme) => ({
  Table_TableCell: {
    borderRight: "1px solid #C0C0C0",
    "& .MuiTableCell-root": {
      padding: "16px 0px",
    },
  },
}));

type ManagementPageTableRowProps = {
  email: string;
  sendOn: string;
  // projectName: string;
  design?: string;
  subject: string;
  status: string;
  id: number;
  project_number?: string;
};

const HistoryPageDataRows: React.FC<ManagementPageTableRowProps> = (props) => {
  const classes = useStyles();
  const sendOn = useMemo(
    () => format(parseISO(props.sendOn), "dd/MM/yyyy HH:mm"),
    [props.sendOn]
  );
  // const date = props.sendOn.substring(0, props.sendOn.length - 16);
  const [isPreviewOpen, setPreviewOpen] = useState(false);

  return (
    <>
      <TableCell className={classes.Table_TableCell} component="th" scope="row">
        {props.email}
      </TableCell>
      <TableCell className={classes.Table_TableCell}>{sendOn}</TableCell>
      <TableCell className={classes.Table_TableCell}>
        {props.project_number}
      </TableCell>
      <TableCell className={classes.Table_TableCell}>{props.design}</TableCell>
      <TableCell className={classes.Table_TableCell}>{props.subject}</TableCell>
      <TableCell>{props.status}</TableCell>
      <TableCell className={classes.Table_TableCell} align={"right"}>
        <IconButton onClick={() => setPreviewOpen(true)}>
          <Visibility />
        </IconButton>
        <PreviewDialog
          mailId={props.id}
          open={isPreviewOpen}
          onClose={() => setPreviewOpen(false)}
        />
        <Link to={`/authorized/mailer/${props.id}`}>
          <IconButton>
            <Edit />
          </IconButton>
        </Link>
      </TableCell>
    </>
  );
};

type PreviewDialogProps = {
  mailId: number;
  open: boolean;
  onClose: () => void;
};

const PreviewDialog: React.FC<PreviewDialogProps> = ({
  mailId,
  open,
  onClose,
}) => {
  const [busy, setBusy] = useState(false);
  const [html, setHtml] = useState<string | null>(null);

  useEffect(() => {
    if (!open) return;
    setBusy(true);

    (async () => {
      const mailRes = await get_api().cdnMailerOnePost({ id: mailId });
      const htmlRes = await get_api().cdnMailerHtmlPost(
        mailRes.data.request_json
      );

      setHtml(htmlRes.data);
      setBusy(false);
    })();
  }, [mailId, open]);

  return (
    <Dialog open={open} onClose={() => !busy && onClose()} maxWidth="xl">
      <DialogContent>
        {busy && <CircularProgress />}
        {!busy && !!html && (
          <iframe
            src={"data:text/html," + encodeURIComponent(html)}
            width={700}
            height={600}
            frameBorder="0"
            title={"prev"}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={busy}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HistoryPageDataRows;

import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { FormikProps } from "formik";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import { CdnMailerRequestIssueDto } from "../../api";
import { MailerFormData } from "./MailerPage";

type Props = {
  issue?: CdnMailerRequestIssueDto;
  issueIndex: number;
  connectionIndex: number;
};

const MailerPageProjectConnectionIssueForm = (
  props: FormikProps<MailerFormData> & Props
) => {
  const { values, errors, touched, handleChange, setFieldValue } = props;
  const { issueIndex } = props;
  const formPrefix = `project.connection_set[${props.connectionIndex}].issue_set[${issueIndex}]`;
  const formValues = values.project!.connection_set?.[props.connectionIndex]
    ?.issue_set?.[issueIndex];
  const formErrors: any = (errors.project?.connection_set as any)?.[
    props.connectionIndex
  ]?.issue_set?.[issueIndex];

  const formTouched: any = (touched.project?.connection_set as any)?.[
    props.connectionIndex
  ]?.issue_set?.[issueIndex];

  const handleDateChange = (name: string) => {
    return (date: any) => {
      if (isValid(date)) {
        setFieldValue(name, format(date, "yyyy-MM-dd"));
      } else {
        setFieldValue(name, null);
      }
    };
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Description"
          name={`${formPrefix}.description`}
          value={formValues?.description || ""}
          onChange={handleChange}
          error={!!formErrors?.description}
          helperText={formTouched?.description ? !!formErrors?.description : ""}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Explanation"
          name={`${formPrefix}.explanation`}
          value={formValues?.explanation || ""}
          onChange={handleChange}
          error={!!formErrors?.explanation}
          helperText={formTouched?.explanation ? !!formErrors?.explanation : ""}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Action Holder"
          name={`${formPrefix}.action_holder`}
          value={formValues?.action_holder || ""}
          onChange={handleChange}
          error={!!formErrors?.action_holder}
          helperText={
            formTouched?.action_holder ? !!formErrors?.action_holder : ""
          }
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Issue Type"
          name={`${formPrefix}.issue_type`}
          value={formValues?.issue_type || ""}
          onChange={handleChange}
          error={!!formErrors?.issue_type}
          helperText={formTouched?.issue_type ? !!formErrors?.issue_type : ""}
        />
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid item xs={6}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd-MM-yyyy"
            margin="normal"
            label="Start Date"
            value={formValues?.date_start || null}
            helperText={formErrors?.date_start || ""}
            error={!!formErrors?.date_start}
            onChange={handleDateChange(`${formPrefix}.date_start`)}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd-MM-yyyy"
            margin="normal"
            label="Update Date"
            value={formValues?.date_update || null}
            helperText={formErrors?.date_update}
            error={!!formErrors?.date_update}
            onChange={handleDateChange(`${formPrefix}.date_update`)}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </Grid>
  );
};

export default MailerPageProjectConnectionIssueForm;

import React from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import sleep from "../../Ext/sleep";
import get_api from "../../Ext/get_api";
import { AxiosError } from "axios";

const useStyles = makeStyles((theme) => ({
  Drawer_Container: {
    "& .MuiPaper-root": {
      width: "40vw",
    },
  },
  Drawer_SubmitBtnProgress: {
    position: "absolute",
    bottom: "0px",
    height: "4px",
    left: "0px",
    right: "0px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    backgroundColor: "transparent",

    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  id: number;
  name: string;
  email: string;
  load: () => void;
};

type FormData = {
  name: string;
  email: string;
};

const validationSchema = Yup.object({
  name: Yup.string().min(1),
  email: Yup.string().min(1).email("Enter a valid email"),
});

const ManagementUserPageDrawer: React.FC<Props> = (props) => {
  const classes = useStyles();

  const values = { name: props.name, email: props.email, id: props.id };
  const onSubmit = async (data: FormData, actions: FormikHelpers<FormData>) => {
    const api = get_api();

    try {
      await sleep(1500);
      await api.crmPersonSavePost({
        name: data.name,
        email: data.email,
        id: props.id,
      });

      actions.setSubmitting(false);
      props.load();
    } catch (e) {
      if (e.response) {
        const exc: AxiosError = e;
        const res = exc.response;
        console.log(res?.status, res?.data, res?.headers);
        if (res?.data.detail[0].type === "value_error.email") {
          actions.setErrors({
            email: "Please enter a valid email",
          });
        } else {
          actions.setErrors({
            email: `something went wrong: ${res?.data.detail.type}`,
          });
        }
      } else {
        console.error(e);
        actions.setErrors({
          email: "Something went wrong",
        });
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={() => props.onClose()}
      className={classes.Drawer_Container}
    >
      <Formik<FormData>
        initialValues={values}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(fp: FormikProps<FormData>) => {
          const {
            values: { name, email },
            errors,
            touched,
            handleSubmit,
            handleChange,
            isSubmitting,
          } = fp;

          return (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              style={{ height: "100%" }}
            >
              <Box
                p={2}
                paddingTop={4}
                display="flex"
                flexDirection="column"
                height="100%"
              >
                <DialogTitle disableTypography>
                  <Typography variant="h4">Edit user</Typography>
                  <Typography variant="subtitle2">Edit this user.</Typography>
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        name="name"
                        value={name}
                        fullWidth
                        label="name"
                        helperText={touched.name ? !!errors.name : ""}
                        error={!!errors.name}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name="email"
                        value={email}
                        helperText={
                          touched.email && !!errors.email ? errors.email : ""
                        }
                        error={!!errors.email}
                        onChange={handleChange}
                        fullWidth
                        label="email address"
                      />
                    </Grid>
                    {/* {errors.email && <div>{errors.email}</div>} */}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={props.onClose}>
                    cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && (
                      <LinearProgress
                        className={classes.Drawer_SubmitBtnProgress}
                      />
                    )}
                    save
                  </Button>
                </DialogActions>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Drawer>
  );
};

export default ManagementUserPageDrawer;

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import HistoryPageFilterDrawer from "./HistoryPageFilterDrawer";

import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import HistoryPageDataRows from "./HistoryPageDataRows";
import _ from "underscore";
import get_api from "../../Ext/get_api";
import { CdnMailerManyRequest, CdnMailerManyResponse } from "../../api/api";

const useStyles = makeStyles((theme) => ({
  Table__TableHeaders: {
    "& .MuiTableCell-stickyHeader": {
      fontWeight: "bold",
      backgroundColor: theme.palette.primary.dark,
      color: "#fff",
      fontSize: "20px",
      padding: "4px 16px 4px 16px",
    },
  },

  Table__Content: {
    height: "calc(100vh - 50px)",
    overflow: "auto",
  },
  Table_TableBody: {
    border: "1px solid #C0C0C0",
  },
  Table_TableCell: {
    borderRight: "1px solid #C0C0C0",
  },
  Table_TableRow: {
    "& .MuiTableCell-root": {
      padding: "16px",
    },
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
};
const HistoryPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(false);
  //const [page, setPage] = useState(0);
  //const [rowsPerPage, setRowsPerPage] = useState(10);
  const [params, setParams] = useState<CdnMailerManyRequest>({
    search: "",
    page: 0,
    amount: 10,
    created_after: undefined,
    created_before: undefined,
    template: undefined,
  });
  const [count, setCount] = useState(0);

  const [records, setRecords] = useState<CdnMailerManyResponse["record_set"]>(
    []
  );

  const load = useCallback(async () => {
    const api = get_api();

    console.log(params);
    const res = await api.cdnMailerManyPost(params);
    console.log(res, "data");

    setCount(res.data.count);
    setRecords(res.data.record_set);
  }, [params]);

  useEffect(() => {
    load();
  }, [params, load]);

  const handleClick = () => {
    setAnchorEl(true);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setParams({ ...params, page: newPage });

    console.log(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setParams({ ...params, amount: event.target.value, page: 0 });

    //console.log(params.amount, "amount?");
  };
  const debounce = _.debounce((searchValue: string) => {
    setParams({ ...params, search: searchValue });
    console.log(searchValue, "en hier dan?");
  }, 500);

  const handleSearch = (event: any) => {
    console.log(event.target.value, "zoekt hij");
    debounce(event.target.value);
  };

  return (
    <>
      <Box
        paddingTop={2}
        pl={2}
        pr={2}
        paddingBottom={2}
        className={classes.Table__Content}
      >
        <Paper>
          <Box ml={2} mr={2}>
            <Grid container>
              <Grid item xs>
                <h2>History</h2>
              </Grid>
              <Grid item>
                <TextField
                  label="Search"
                  // geen state, dus zal afwijken wanneer je deze instelt in het filter.
                  onChange={handleSearch}
                  InputProps={{
                    endAdornment: (
                      <SearchOutlinedIcon style={{ marginRight: "25px" }} />
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <TableContainer>
            <Table stickyHeader className={classes.Table__TableHeaders}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Email address</TableCell>
                  <TableCell align="left">Send on</TableCell>
                  <TableCell align="left">Project number</TableCell>
                  <TableCell align="left">Template</TableCell>
                  <TableCell align="left">Subject</TableCell>
                  <TableCell align="left">Status</TableCell>

                  <TableCell align="right">
                    filter
                    <IconButton
                      onClick={() => handleClick()}
                      style={{ color: "#fff" }}
                    >
                      <ArrowDropDownCircleOutlinedIcon />
                    </IconButton>
                    <HistoryPageFilterDrawer
                      initial={params}
                      open={anchorEl}
                      onClose={(data) => {
                        setParams({ ...params, ...data });

                        setAnchorEl(false);
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.Table_TableBody}>
                {records.map((row, index) => (
                  <TableRow key={index} className={classes.Table_TableRow}>
                    <HistoryPageDataRows
                      email={row.from_email}
                      sendOn={row.created_at}
                      project_number={row.project_number}
                      design={row.request_json.message.template}
                      id={row.id}
                      subject={row.subject}
                      status={row.status}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box p={2}>
            <TablePagination
              component={"div"}
              count={count}
              page={params.page}
              rowsPerPage={params.amount}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default HistoryPage;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  makeStyles,
  Checkbox,
  Box,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { CdnMailerRequestConnectionDto } from "../../api";
import { MailerFormData } from "./MailerPage";
import MailerPageProjectConnectionIssueForm from "./MailerPageProjectConnectionIssueForm";
import format from "date-fns/format";
import isValid from "date-fns/isValid";

const useStyles = makeStyles((theme) => ({
  Module__Accordion: {
    "&:not(:first-child)": {
      marginTop: "10px",
    },
  },
}));

type Props = {
  connection?: CdnMailerRequestConnectionDto;
  connectionIndex: number;
};

const MailerPageProjectConnectionForm = (
  props: FormikProps<MailerFormData> & Props
) => {
  const classes = useStyles();
  const { values, errors, touched, handleChange, setFieldValue } = props;
  const { connectionIndex } = props;
  const [milestoneAmount, setMilestoneAmount] = useState(1);
  const [issueIndex, setIssueIndex] = useState(0);
  const [issueAmount, setIssueAmount] = useState(0);

  const formPrefix = `project.connection_set[${connectionIndex}]`;
  const formValues = values.project!.connection_set?.[connectionIndex];
  const formTouched: any = (touched.project?.connection_set as any)?.[
    connectionIndex
  ];
  const formErrors: any = errors.project?.connection_set?.[connectionIndex];

  const handleDateChange = (name: string) => {
    return (date: any) => {
      if (isValid(date)) {
        setFieldValue(name, format(date, "yyyy-MM-dd"));
      } else {
        setFieldValue(name, null);
      }
    };
  };

  useEffect(() => {
    if (!formValues?.issue_set?.length) {
      setIssueAmount(0);
    } else {
      setIssueAmount(formValues?.issue_set.length);
      console.log(formValues?.issue_set.length);
    }
    if (!formValues?.milestone_set?.length) {
      setMilestoneAmount(0);
    } else {
      setMilestoneAmount(formValues?.milestone_set.length);
    }
  }, [formValues?.issue_set?.length, formValues?.milestone_set?.length]);

  return (
    <Accordion className={classes.Module__Accordion} style={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <h1>Connection </h1>
      </AccordionSummary>
      <Box p={2}>
        <Accordion className={classes.Module__Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Box paddingLeft={2}>
              <h2>Data Form {connectionIndex + 1}</h2>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: "30px" }}>
                <TextField
                  fullWidth
                  label="Title"
                  name={`${formPrefix}.title`}
                  value={formValues?.title || ""}
                  error={!!formErrors?.title}
                  helperText={formTouched?.title ? !!formErrors?.title : ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "30px" }}>
                <TextField
                  fullWidth
                  label="Location"
                  name={`${formPrefix}.location`}
                  value={formValues?.location || ""}
                  error={!!formErrors?.location}
                  helperText={
                    formTouched?.location ? !!formErrors?.location : ""
                  }
                  onChange={handleChange}
                />
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={4} style={{ marginBottom: "30px" }}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM-dd-yyyy"
                    margin="normal"
                    label="Delivery Expected"
                    value={formValues?.date_delivery_expected || null}
                    onChange={handleDateChange(
                      `${formPrefix}date_delivery_expected`
                    )}
                    helperText={formErrors?.date_delivery_expected}
                    error={!!formErrors?.date_delivery_expected}
                  />
                </Grid>

                <Grid item xs={4} style={{ marginBottom: "30px" }}>
                  <KeyboardDatePicker
                    disableToolbar
                    label="Delivery Date"
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    value={formValues?.date_delivery || null}
                    onChange={handleDateChange(`${formPrefix}date_delivery`)}
                    helperText={formErrors?.date_delivery}
                    error={!!formErrors?.date_delivery}
                  />
                </Grid>

                <Grid item xs={4} style={{ marginBottom: "30px" }}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="Delivery Changed"
                    value={formValues?.date_delivery_changed || null}
                    error={!!formErrors?.date_delivery_changed}
                    helperText={formErrors?.date_delivery_changed}
                    onChange={handleDateChange(
                      `${formPrefix}date_delivery_changed`
                    )}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Milestone Amount"
                  name="milestoneAmount"
                  value={milestoneAmount}
                  onChange={(evt) =>
                    setMilestoneAmount(parseInt(evt.target.value, 10) || 0)
                  }
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "30px" }}>
                {[...Array(milestoneAmount)].map((x, ix) => (
                  <div key={ix}>
                    <Checkbox
                      value={milestoneAmount}
                      checked={true}
                      color="primary"
                      onChange={() => setMilestoneAmount(ix)}
                    />
                    <TextField
                      fullWidth
                      label="Milestone"
                      name={`${formPrefix}.milestone_set[${ix}].value`}
                      value={formValues?.milestone_set?.[ix]?.value || ""}
                      error={!!formErrors?.milestone_set?.value}
                      helperText={
                        formTouched?.milestone_set?.value
                          ? !!formErrors?.milestone_set?.value
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </div>
                ))}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box p={2}>
        <Accordion className={classes.Module__Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Box paddingLeft={2}>
              <h2>Issue Form {issueIndex + 1}</h2>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block", width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Issue Amount"
                  name="issueAmount"
                  value={issueAmount.toString()}
                  onChange={(evt) =>
                    setIssueAmount(parseInt(evt.target.value, 10) || 0)
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Paper>
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={issueIndex}
                    variant="scrollable"
                    scrollButtons={"on"}
                  >
                    {[...Array(issueAmount)].map((x, ix) => (
                      <Tab
                        label={`# ${ix + 1}`}
                        value={ix}
                        onClick={() => setIssueIndex(ix)}
                        key={ix}
                      />
                    ))}
                  </Tabs>
                </Paper>
              </Grid>

              <MailerPageProjectConnectionIssueForm
                issue={
                  values.project?.connection_set?.[connectionIndex]
                    ?.issue_set?.[issueIndex]
                }
                issueIndex={issueIndex}
                {...props}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Accordion>
  );
};

export default MailerPageProjectConnectionForm;

import React from "react";
import { FormikProps } from "formik";
import { MailerFormData } from "./MailerPage";
import { CdnMailerRequestProgressPopDto } from "../../api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  Module__Accordion: {
    "&:not(:first-child)": {
      marginTop: "10px",
    },
  },
}));
type Props = {
  pop?: CdnMailerRequestProgressPopDto;
};
const MailerPageProgressPopForm = (
  props: FormikProps<MailerFormData> & Props
) => {
  const classes = useStyles();
  const { values, errors, touched, handleChange } = props;
  const formValues = values.progress?.pop;
  const formPrefix = `progress.pop`;
  const formErrors: any = errors.progress?.pop;
  const formTouched: any = touched.progress?.pop;

  return (
    <Box>
      <Accordion
        className={classes.Module__Accordion}
        style={{ marginBottom: "30px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Box paddingLeft={2}>
            <h2>POP</h2>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Cabinet Id"
                name={`${formPrefix}.cabinet_id`}
                value={formValues?.cabinet_id}
                error={!!formErrors?.cabinet_id}
                helperText={
                  formTouched?.cabinet_id ? formErrors?.cabinet_id : ""
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Network Element"
                name={`${formPrefix}.network_element`}
                value={formValues?.network_element}
                error={!!formErrors?.network_element}
                helperText={
                  formTouched?.network_element
                    ? !!formErrors?.network_element
                    : ""
                }
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Gate Position"
                name={`${formPrefix}.gate_position`}
                value={formValues?.gate_position}
                error={!!formErrors?.gate_position}
                helperText={
                  formTouched?.gate_position ? !!formErrors?.gate_position : ""
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Transceiver"
                name={`${formPrefix}.transceiver`}
                value={formValues?.transceiver}
                error={!!formErrors?.transceiver}
                helperText={
                  formTouched?.transceiver ? !!formErrors?.transceiver : ""
                }
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default MailerPageProgressPopForm;

import { AppBar, Button, makeStyles, Toolbar } from "@material-ui/core";
import React from "react";
import Logo from "../../assets/eurofiber - excite.png";

const useStyles = makeStyles((theme) => ({
  Logo: {
    width: "80px",
    height: "50px",
    backgroundPosition: "left",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    marginRight: "10px",
  },

  Layout__AppBar: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const AuthorizedLayout: React.FC = (props) => {
  const classes = useStyles();

  return (
    <div>
      <AppBar position="sticky" className={classes.Layout__AppBar}>
        <Toolbar>
          <Button href="/authorized/dashboard">
            <div
              className={classes.Logo}
              style={{ backgroundImage: `url('${Logo}')` }}
            ></div>
          </Button>
          <Button href="/authorized/users" color="inherit">
            Users
          </Button>
          <Button href="/authorized/language" color="inherit">
            Language
          </Button>
          <Button href="/authorized/mailer" color="inherit">
            Mailer
          </Button>

          <Button color="inherit">Api</Button>
          <Button href="/authorized/history" color="inherit">
            History
          </Button>
          <Button href="/unauthorized/login" color="inherit">
            Sign out
          </Button>
        </Toolbar>
      </AppBar>

      {props.children}
    </div>
  );
};

export default AuthorizedLayout;

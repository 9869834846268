import React from "react";
import { makeStyles } from "@material-ui/core";
import Logo from "../../assets/eurofiber - excite.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },

  Logo: {
    width: "100%",
    height: 100,
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    marginTop: "30px",
  },
}));

const UnauthorizedLayout: React.FC = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        className={classes.Logo}
        style={{ backgroundImage: `url('${Logo}')` }}
      ></div>

      {props.children}

      <footer
        style={{
          marginTop: "200px",
          textAlign: "center",
        }}
      >
        Ⓒ Eurofiber, development by
        <a
          style={{ textDecoration: "none", color: "#000", marginLeft: "5px" }}
          href=" https://corverdevelopment.nl"
        >
          CoDe
        </a>
      </footer>
    </div>
  );
};

export default UnauthorizedLayout;

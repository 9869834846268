import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton, makeStyles, TableCell, TableRow } from "@material-ui/core";
import ManagementUserPageEditDrawer from "./ManagementUserPageEditDrawer";
import ManagementUserPageChangePasswordDialog from "./ManagementUserPageChangePasswordDialog";
import ManagementUserPageRecoverPasswordDialog from "./ManagementUserPageRecoverPasswordDialog";
import ManagementUserPageArchiveDialog from "./ManagementUserPageArchiveDialog";

const useStyles = makeStyles((theme) => ({
  Table__Cell: {
    borderRight: "1px solid #C0C0C0",
    padding: "0px 16px",
  },
  Table__incon: {
    whiteSpace: "nowrap",
  },

  Table_Row: {
    "& .MuiTableCell-root": {
      padding: "0px 16px",
    },
  },
}));

type ManagementPageTableRowProps = {
  name: string;
  email: string;
  id: number;
  load: () => void;
};

const ManagementUserPageDataRows: React.FC<ManagementPageTableRowProps> = (
  props
) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const [dialogChange, setDialogChange] = useState(false);
  const [dialogRecover, setDialogRecover] = useState(false);
  const [dialogArchive, setDialogArchive] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setDrawer(true);
  };

  const handleDialogChange = () => {
    setDialogChange(true);
  };

  const handleDialogRecover = () => {
    setDialogRecover(true);
  };
  const handleDialogArchive = () => {
    setDialogArchive(true);
  };
  return (
    <>
      <TableRow key={props.name} className={classes.Table_Row}>
        <TableCell className={classes.Table__Cell} align="left">
          {props.id}
        </TableCell>
        <TableCell className={classes.Table__Cell} component="th" scope="row">
          {props.name}
        </TableCell>
        <TableCell className={classes.Table__Cell} align="left">
          {props.email}
        </TableCell>

        <TableCell
          className={(classes.Table__Cell, classes.Table__incon)}
          align="right"
        >
          <IconButton aria-label="edit" onClick={() => handleOpen()}>
            <EditIcon className={classes.Table__incon} />
          </IconButton>

          <IconButton onClick={(event) => handleClick(event)}>
            <ArrowDropDownCircleOutlinedIcon className={classes.Table__incon} />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleDialogChange()}>
              change password
            </MenuItem>
            <MenuItem onClick={() => handleDialogArchive()}>
              archive user
            </MenuItem>
            <MenuItem onClick={() => handleDialogRecover()}>
              recover password
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <ManagementUserPageChangePasswordDialog
        open={dialogChange}
        onClose={() => setDialogChange(false)}
        id={props.id}
        email={props.email}
      />
      <ManagementUserPageEditDrawer
        open={drawer}
        onClose={() => {
          setDrawer(false);
        }}
        id={props.id}
        name={props.name}
        email={props.email}
        load={props.load}
      />

      <ManagementUserPageRecoverPasswordDialog
        open={dialogRecover}
        onClose={() => setDialogRecover(false)}
        email={props.email}
        id={props.id}
        load={props.load}
      />

      <ManagementUserPageArchiveDialog
        open={dialogArchive}
        onClose={() => setDialogArchive(false)}
        id={props.id}
        name={props.name}
        load={props.load}
      />
    </>
  );
};

export default ManagementUserPageDataRows;

import React from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "../../theme";

const CoreLayout: React.FC = ({ children }) => {
  const mui_theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: "light",

          primary: { main: theme.primary },
          secondary: { main: theme.secondary },

          contrastThreshold: 3,
          tonalOffset: 0.2,
        },
        typography: {
          fontSize: 15,
          h1: {
            fontFamily: "'Noto Sans JP', sans-serif",
          },
          h2: {
            fontFamily: "'Noto Sans JP', sans-serif",
          },
          h3: {
            fontFamily: "'Noto Sans JP', sans-serif",
          },
          h4: {
            fontFamily: "'Noto Sans JP', sans-serif",
          },
          h5: {
            fontFamily: "'Noto Sans JP', sans-serif",
          },
          h6: {
            fontFamily: "'Noto Sans JP', sans-serif",
          },
        },
        overrides: {
          MuiListItemIcon: {
            root: {
              // minWidth: 36,
              color: theme.primary,
            },
          },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={mui_theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default CoreLayout;

import React, { useState } from "react";
import { IconButton, makeStyles, TableCell } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ManagementLanguagePageEditDrawer from "./ManagementLanguagePageEditDrawer";

const useStyles = makeStyles((theme) => ({
  Table__Rows: {
    borderRight: "1px solid #C0C0C0",
    padding: "0px 16px",
  },
  Drawer_Container: {
    "& .MuiPaper-root": {
      width: "30vw",
    },
  },
}));

type ManagementPageTableCellProps = {
  language: string;
  value: string;
  id: number;
  load: () => void;
};
const ManagementLanguagePageTableCell: React.FC<ManagementPageTableCellProps> = (
  props
) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const classes = useStyles();
  return (
    <>
      <TableCell align="left">{props.value} </TableCell>
      <TableCell className={classes.Table__Rows} align="left" width={1}>
        <IconButton aria-label="edit" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </TableCell>

      <ManagementLanguagePageEditDrawer
        open={open}
        handleClose={() => setOpen(false)}
        language={props.language}
        value={props.value}
        id={props.id}
        load={props.load}
      />
    </>
  );
};

export default ManagementLanguagePageTableCell;

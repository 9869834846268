import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";

import PersonAddIcon from "@material-ui/icons/PersonAdd";

import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import ManagementUserPageDataRows from "./ManagementUserPageDataRows";
import ManagementUserPageAddUserDrawer from "./ManagementUserPageAddUserDrawer";
import get_api from "../../Ext/get_api";
import { CrmPersonManyRequest, CrmPersonManyResponse } from "../../api";
import _ from "underscore";

const useStyles = makeStyles((theme) => ({
  Table__Content: {
    height: "calc(100vh - 50px)",
    overflow: "auto",
  },

  Table__TableHeaders: {
    "& .MuiTableCell-stickyHeader": {
      fontWeight: "bold",
      backgroundColor: theme.palette.primary.dark,
      color: "#fff",
      fontSize: "20px",
      padding: "4px 16px 4px 16px",
    },
  },

  Table_TableBody: {
    border: "1px solid #C0C0C0",
  },
}));

const ManagementUserPage = () => {
  const [drawer, setDrawer] = useState(false);

  const [params, setParams] = useState<CrmPersonManyRequest>({
    limit: 10,
    offset: 0,
    search: "",
  });

  const [records, setRecords] = useState<CrmPersonManyResponse["records"]>([]);

  const [pagination, setPagination] = useState<
    CrmPersonManyResponse["pagination"]
  >({ count: 0, offset: 0, limit: 10 });

  const load = useCallback(async () => {
    const api = get_api();
    const res = await api.crmPersonManyPost(params);
    console.log(res, "data");
    setRecords(res.data.records);

    setPagination(res.data.pagination);
  }, [params]);

  useEffect(() => {
    load();
  }, [params, load]);

  const handleChangePage = (event: any, newPage: number) => {
    setParams({ ...params, offset: newPage * 10 });
    console.log(newPage, "page");
  };

  const handleChangeRowsPerPage = (event: any) => {
    setParams({ ...params, limit: event.target.value, offset: 0 });
    console.log(params.limit, "lmit?");
  };
  const handleOpen = () => {
    setDrawer(true);
  };

  const debounce = _.debounce((searchValue: string) => {
    setParams({ search: searchValue, offset: 0 });
  }, 500);

  const handleSearch = (event: any) => {
    debounce(event.target.value);
  };

  const classes = useStyles();

  return (
    <>
      <Box
        paddingTop={2}
        pl={2}
        pr={2}
        paddingBottom={2}
        className={classes.Table__Content}
      >
        <Paper>
          <Box ml={2} mr={2}>
            <Grid container>
              <Grid item xs>
                <h2>Users Info</h2>
              </Grid>
              <Grid item>
                <TextField
                  label="Search"
                  onChange={handleSearch}
                  InputProps={{
                    endAdornment: (
                      <SearchOutlinedIcon style={{ marginRight: "25px" }} />
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <TableContainer>
            <Table stickyHeader className={classes.Table__TableHeaders}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">User ID</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Email</TableCell>

                  <TableCell align="right" width={1}>
                    <IconButton onClick={() => handleOpen()}>
                      <PersonAddIcon style={{ color: "#fff" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.Table_TableBody}>
                {records.map((row) => (
                  <ManagementUserPageDataRows
                    key={row.name}
                    name={row.name}
                    email={row.email}
                    id={row.id}
                    load={() => load()}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ManagementUserPageAddUserDrawer
            open={drawer}
            onClose={() => {
              setDrawer(false);
              load();
            }}
          />
          <Box p={2}>
            <TablePagination
              count={pagination.count}
              page={pagination.offset / pagination.limit}
              rowsPerPage={pagination.limit}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default ManagementUserPage;

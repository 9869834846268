import React from "react";
import {
  Box,
  Button,
  LinearProgress,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { FormikProps } from "formik";
import { LoginRecoverPasswordPageFormData } from "./LoginRecoverPasswordPage";

const useStyles = makeStyles((theme) => ({
  Login__Form: {
    margin: "0px 30px ",
  },
  Login__Inputfield: {
    display: "block",
  },
  Login__Btn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },

  Login__BtnLoader: {
    position: "absolute",
    bottom: "0",
    height: "4px",
    left: "0",
    right: "0",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    backgroundColor: "transparent",

    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  Login__BoxBtns: {
    flexDirection: "column",
    margin: "80px 100px",
  },

  Login__PasswordBtn: {
    color: theme.palette.primary.light,
  },
}));
const LoginForgotPasswordPageForm = (
  props: FormikProps<LoginRecoverPasswordPageFormData>
) => {
  const classes = useStyles();

  const {
    values: { email, password, code_token },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = props;

  return (
    <form
      noValidate
      autoComplete="off"
      className={classes.Login__Form}
      onSubmit={handleSubmit}
    >
      <TextField
        name="email"
        helperText={touched.email && !!errors.email ? errors.email : ""}
        error={!!errors.email}
        label="email address"
        fullWidth
        className={classes.Login__Inputfield}
        value={email}
        onChange={handleChange}
      />

      <TextField
        name="code_token"
        label="code token"
        fullWidth
        className={classes.Login__Inputfield}
        value={code_token}
        onChange={handleChange}
        helperText={
          touched.code_token && !!errors.code_token ? errors.code_token : ""
        }
        error={!!errors.code_token}
      />
      <TextField
        name="password"
        label="new password"
        type="password"
        fullWidth
        className={classes.Login__Inputfield}
        value={password}
        onChange={handleChange}
        helperText={
          touched.password && !!errors.password ? errors.password : ""
        }
        error={!!errors.password}
      />

      <Box
        className={classes.Login__BoxBtns}
        display="flex"
        justifyContent="space-between"
      >
        <Button
          type="submit"
          className={classes.Login__Btn}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <LinearProgress className={classes.Login__BtnLoader} />
          )}
          Submit
        </Button>

        <Button href="/unauthorized/login" color="primary">
          cancel
        </Button>
      </Box>
    </form>
  );
};

export default LoginForgotPasswordPageForm;
